import styled from 'styled-components'

export const PhotoContainer = styled.div`
    background: #222;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0px;
    height: 200px;
    position: relative;
    z-index: 1;


    @media screen and (max-width: 768px) {
        height: 100px;

}
`;

export const Heading = styled.h1`
    text-align: center;
    color: #fff;
    font-size: 4rem;
    text-transform: uppercase;

    @media screen and (max-width:480px) {
        font-size: 2rem;
    }
`
export const PhotoContent = styled.div`
 z-index: 3;
 /* max-width: 1200px; */
 position: absolute;
 /* padding: 8px 24px; */
 display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: center;
`;

export const PhotoBg = styled.img`
    width: 800px;
    height: 50%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* filter: brightness(60%); */

    @media screen and (max-width: 768px) {
        width: 90%;
    }
`

export const Container1 = styled.div`
    height: 600px;
    background: transparent;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;


    @media screen and (max-width: 768px) {
    height:300px;

}
`
export const Subheading = styled.p`
    max-width: 48%;
    height: auto;
    font-size: 18px;
    line-height: 24px;
    color: #0c0c0c;
    text-align: justify;
    margin-bottom: 10px;

    @media screen and (max-width: 768px) {
        max-width: 86%;
        font-size: 14px;
    }
`;

export const Container2 = styled.div`
    height: 2200px;
    background: transparent;
    align-items: center;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1800px) {
    height:2200px;
    }

    @media screen and (max-width: 768px) {
    height:2200px;

}
`

export const ImgWrap = styled.div`
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0;
    margin-top: 20px;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;    }
`;

export const Icon = styled.img`
    width: 300px;
    height: 200px;
    margin-bottom: 5px;

    @media screen and (max-width: 768px) {
        width: 70%;
        height: auto;
        }
`;

export const Map = styled.img`
    width: 50%;
    height: auto;
    margin: -80px 0 -100px 0;

    @media screen and (max-width: 768px) {
        width: 90%;
        margin: -40px 0 -40px 0;
    }  
`

export const V1Wrap = styled.div`
    display: block;
    margin-bottom: 30px;
    margin-top: 10px;
    @media screen and (max-width: 768px) {
        display: none;
    }  
`

export const V2Wrap = styled.div`
    display: none;
    margin-bottom: 30px;
    margin-top: 10px;
    @media screen and (max-width: 768px) {
        display: block;
    }  
`

export const StyledLink = styled.a`
    text-decoration-color: #3457D5;
    color: #3457D5;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration-color: #3457D5;
        color: #3457D5;
    }
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px;
    
`
export const Caption = styled.p`
    max-width: 300px;
    height: auto;
    font-size: 9px;
    color: #0c0c0c;
    text-align: justify;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
        max-width: 70%;
        font-size: 9px;
    }
`;
