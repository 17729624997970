import React from 'react'
import { NGOHeaderWrap, NGOIcon, NGOInfo, NGOName, NGOPageContainer1, NGOPageContainer2, NGOLeft, NGORight, NGOContentColumn1,NGOContentColumn2, NGOCovidInfoH1, NGOCovidInfop, NGOCardWrap, NGOCovidCard, NGOPageContainer3, NGOMap, NGOPageContainer4,  NGOAwards, NGOPageContainer5, NGOPageContainer6, NGOPageContainer7, Red, NGOQuote } from './NGOPageElements'
import CovidTable from './CovidTable'
import AboutTable from './AboutTable'
import ItemTable from './ItemTable'
import Slideshow from './Slideshow'
import JsxParser from 'react-jsx-parser'
import './styles.css'

const NGOPage = ({ iconSrc, card1, card2, card3, card4, map, awards, language }) => {
    let content = {
        English: {
            head1: "Community served: Scheduled Castes, Scheduled Tribes and Women",
            head2: "Area of operation: 428 villages across Uttar Pradesh and Madhya Pradesh",
            head3: "Estimated cost of COVID relief project: USD 8,000/ JPY 870,000/ INR 6,00,000",
            title1: "CURRENT COVID RELIEF PROPOSAL",
            para1: `Parmarth provides <Red>ration kits </Red>to <Red>migrants</Red> returning to villages and <Red>families living in poverty.</Red> It is also conducting an <Red>awareness campaign</Red> along with a distribution of <Red>sanitation kits.</Red>`,
            para2: `Our support of USD 8,000/ JPY 870,000/ INR 6,00,000 would help Parmarth reach 250 families with <Red>medicine kits, ration kits </Red>and <Red>warm-up kits</Red> for children. Warm-up kits function as incubators for infants between the ages of 0-2 years and will help them survive a COVID-19 infection in the third-wave that is <a href ='https://www.indiatoday.in/coronavirus-outbreak/story/covid-second-wave-end-july-third-wave-six-months-govt-panel-1804512-2021-05-19'>supposed to </a>engulf India later this year. `,
            title2: "OVERVIEW",
            para3: `Parmarth is working for the <Red>weaker and deprived sections of the society.</Red> The organization has been <a href ='https://www.parmarthindia.com/working-models.html'>working</a> to bring <Red>qualitative improvement & changes</Red> in lives of the vulnerable.`,
            para4: `Parmarth is also a local partner of Asia Initiatives in India.  The <a href ='https://www.parmarthindia.com/focus_area.html'>focus areas</a> of Parmarth include:`,
            para5: "• Integrated water resource management",
            para6: "• Agriculture & livelihood Promotion",
            para7: "• Water & sanitation Rights with gender & inclusion perspective",
            para8: "• Women and girls’ rights",
            para9: "• Education (with a special focus on Dalit, Adivasi and girl children)",
            para10: "• HIV & AIDS – health & nutrition",
            title3: "ABOUT PARMARTH",
            title4: "EFFORTS SINCE MARCH 2020",
            para11: `Parmarth Samaj Sevi Sansthan <Red>provided emergency relief</Red> to vulnerable populations in <Red>14 districts of UP and MP. </Red> The major activities included, providing <Red>food packets</Red> to the migrants returning to their villages, <Red>ration kits </Red>to migrants and poor families, <Red>distribution of masks, soaps and sanitizers</Red> and linking eligible persons to various social security schemes of the government.`,
            para12: `Parmarth also reached around <Red>half a million</Red> people through their <Red>Corona awareness Campaign.</Red>`,
            title5: "AWARDS & MEDIA COVERAGE",
            para13: `Parmarth has been awarded the National Water Mission Award 2019 for Water Conservation by Jal Shakti Ministry, Government of India.`,
            para14: `Parmarth’s work has been <a href='https://www.parmarthindia.com/press-release.html'>covered</a> by Dainik Bhaskar (2020), The Hindu (2019) and various others. More work by Parmarth can be found in their <a href='https://www.parmarthindia.com/latest-articles.html'>latest articles,</a> <a href='https://www.parmarthindia.com/success-stories.html'>success stories</a> and <a href='https://www.parmarthindia.com/news-events.html'>news</a> here.`,
            quote: `<blockquote><p>Parmarth has helped me to continue my education and my ambition to do good for the society. I wish many more girls get educated and bring about change in their lives.</p><footer>Ari, a girl student from a village in UP</footer></blockquote>`

            
        },
        Japanese: {
            head1: "担当するコミュニティ：指定カースト(不可触民)、指定部族、女性",
            head2: "活動地域：ウッタル・プラデーシュ州とマディヤ・プラデーシュ州の428の村 ",
            head3: "コロナ支援策の想定される費用：USD 8,000/ JPY 870,000/ INR 6,00,000",
            title1: "現在のコロナ支援案",
            para1: `Parmarthは集落に戻ってきた<Red>移民や貧しい家庭</Red>に<Red>食糧キット</Red>を提供しています。また、衛生キットの配布とともに、<Red>意識向上のための活動</Red>も行っています。`,
            para2: `私たちの87万円の支援は、Parmarthが250世帯に<Red>医薬用品</Red>、<Red>食糧キット</Red>、子供用の<Red>体を温めるキット</Red>を提供するのに役立ちます。この暖を取るキットは、<Red>0〜2歳の乳児のための保育器</Red>として機能し、今年末にインドを襲うと言われている<a href ='https://www.indiatoday.in/coronavirus-outbreak/story/covid-second-wave-end-july-third-wave-six-months-govt-panel-1804512-2021-05-19'>第3波の新型コロナウイルス感染</a>を乗り切るために役立ちます。 `,
            title2: "概要",
            para3: `Parmarthは、資源に恵まれない、社会的に弱い立場にある人々のために活動しています。この組織は、社会的に弱い立場にある人の生活に質的な変化、向上をもたらすために取り組んできました。`,
            para4: `Parmarthはインドのアジア・イニシアティブズの現地パートナーでもあります。<a href ='https://www.parmarthindia.com/focus_area.html'>Parmarthの中心事業</a>`,
            para5: "• 統合水資源管理 ",
            para6: "•農業・生活推進",
            para7: "• ジェンダーとインクルージョンの観点からの水と衛生の権利",
            para8: "• 女性と女児の権利",
            para9: "• 教育（特にダリット、アディバシ、女児)",
            para10: "• HIV/AIDS - 健康と栄養",
            title3: "Parmarthについて",
            title4: "2020年3月以降の活動成果",
            para11: `Parmarth Samaj Sevi Sansthanは、<Red>UPとMPの14地区</Red>で<Red>緊急支援物資</Red>を社会的に脆弱な人々に提供しました。主な活動としては、集落へ戻った移民への<Red>食糧パケット</Red>の支給、移民や貧しい家庭への<Red>食糧キット</Red>の提供、<Red>マスクと石鹸、消毒剤の配布</Red>、そして、政府の様々な社会安全事業へ、適した人物をつなげることがあります。`,
            para12: `また、Parmarthは新型コロナウイルスに関する<Red>意識向上のキャンペーン</Red>「Model Prevention Co-Creation Rural Futures」を通して、<Red>約50万人</Red>の人々と繋がりを持ちました。`,
            title5: "受賞とメディアでの報道",
            para13: `Parmarthは、水の保全活動においてthe National Water Mission Award 2019を、Shakti省、インド政府から受賞しました。`,
            para14: `Parmarthの活動は、2020年にDainik Bhaskarに、2019年にはThe Hinduや様々なメディアに<a href='https://www.parmarthindia.com/press-release.html'>報道</a>されてきました。Parmarthのさらなる活動については、こちらの<a href='https://www.parmarthindia.com/latest-articles.html'>最新の記事</a>、<a href='https://www.parmarthindia.com/success-stories.html'>達成への軌跡</a> 、<a href='https://www.parmarthindia.com/news-events.html'>ニュース</a>をご覧ください。`,
            quote: `<blockquote><p>Parmathは、私が教育を受け続けられるように支え、社会貢献に対する意欲を伸ばすことを助けてくれています。もっと多くの女の子が教育を受け、人生に変化をもたらすことを望んでいます。</p><footer>Ari : UPの村落出身の女子生徒</footer></blockquote>`
        }
      };
      language === "Japanese" ? (content = content.Japanese) : (content = content.English);
    return (
        <>
            <NGOPageContainer1>
                <NGOHeaderWrap>
                    <NGOLeft>
                        <NGOIcon src={iconSrc}/>
                    </NGOLeft>
                    <NGORight>
                        <NGOName>Parmarth Samaj Sevi Sansthan</NGOName>
                        <NGOInfo><JsxParser jsx={content.head1} components={{ Red }}/></NGOInfo>
                        <NGOInfo><JsxParser jsx={content.head2} components={{ Red }}/></NGOInfo>
                        <NGOInfo><JsxParser jsx={content.head3} components={{ Red }}/></NGOInfo>
                    </NGORight>
                </NGOHeaderWrap>
            </NGOPageContainer1>

            <NGOPageContainer2>
                    <NGOContentColumn1>
                        <NGOCovidInfoH1><JsxParser jsx={content.title1} components={{ Red }}/></NGOCovidInfoH1>
                        <NGOCovidInfop><JsxParser jsx={content.para1} components={{ Red }}/></NGOCovidInfop>
                        <NGOCovidInfop><JsxParser jsx={content.para2} components={{ Red }}/></NGOCovidInfop>
                        <CovidTable language={language}/>
                    </NGOContentColumn1>
                    <NGOContentColumn2>
                        <NGOCardWrap>
                            <NGOCovidCard src ={card1}/>
                            <NGOCovidCard src ={card2}/>
                            <NGOCovidCard src ={card3}/>
                            <NGOCovidCard src ={card4}/>
                        </NGOCardWrap>
                    </NGOContentColumn2>
            </NGOPageContainer2>

            <NGOPageContainer3>
                    <NGOContentColumn1>
                    <NGOCovidInfoH1><JsxParser jsx={content.title2} components={{ Red }}/></NGOCovidInfoH1>
                        <NGOCovidInfop><JsxParser jsx={content.para3} components={{ Red }}/></NGOCovidInfop>
                        <NGOCovidInfop><JsxParser jsx={content.para4} components={{ Red }}/></NGOCovidInfop>
                        <NGOCovidInfop><JsxParser jsx={content.para5} components={{ Red }}/></NGOCovidInfop>
                        <NGOCovidInfop><JsxParser jsx={content.para6} components={{ Red }}/></NGOCovidInfop>
                        <NGOCovidInfop><JsxParser jsx={content.para7} components={{ Red }}/></NGOCovidInfop>
                        <NGOCovidInfop><JsxParser jsx={content.para8} components={{ Red }}/></NGOCovidInfop>
                        <NGOCovidInfop><JsxParser jsx={content.para9} components={{ Red }}/></NGOCovidInfop>
                        <NGOCovidInfop><JsxParser jsx={content.para10} components={{ Red }}/></NGOCovidInfop>
                    </NGOContentColumn1>
                    <NGOContentColumn2>
                    <Slideshow language={language}/>
                    </NGOContentColumn2>
            </NGOPageContainer3>

            <NGOPageContainer4>
                    <NGOContentColumn1>
                        <NGOCovidInfoH1><JsxParser jsx={content.title3} components={{ Red }}/></NGOCovidInfoH1>
                        <AboutTable language={language}/>
                    </NGOContentColumn1>
                    <NGOContentColumn2>
                        <NGOMap src = {map}/>
                        
                    </NGOContentColumn2>
            </NGOPageContainer4>

            <NGOPageContainer5>
                    <NGOContentColumn1>
                    <NGOCovidInfoH1><JsxParser jsx={content.title4} components={{ Red }}/></NGOCovidInfoH1>
                    <NGOCovidInfop><JsxParser jsx={content.para11} components={{ Red }}/></NGOCovidInfop>
                    <NGOCovidInfop><JsxParser jsx={content.para12} components={{ Red }}/></NGOCovidInfop>
                    </NGOContentColumn1>
                    <NGOContentColumn2>
                        <ItemTable language={language}/>
                    </NGOContentColumn2>
            </NGOPageContainer5>

            <NGOPageContainer6>
                <NGOContentColumn1>
                    <NGOCovidInfoH1><JsxParser jsx={content.title5} components={{ Red }}/></NGOCovidInfoH1>
                    <NGOCovidInfop><JsxParser jsx={content.para13} components={{ Red }}/></NGOCovidInfop>
                    <NGOCovidInfop><JsxParser jsx={content.para14} components={{ Red }}/></NGOCovidInfop>
                    </NGOContentColumn1>
                    <NGOContentColumn2>
                    <NGOQuote><JsxParser jsx={content.quote} components={{ Red }}/></NGOQuote>
                </NGOContentColumn2>
                
            </NGOPageContainer6>
            <NGOPageContainer7> <NGOAwards src={awards}/></NGOPageContainer7>
           
    </>
    )
}

export default NGOPage
