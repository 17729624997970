import React from 'react';
import { Table, Tr, Td } from 'react-super-responsive-table';
import './CovidTable.css'

export default function ItemTable({i, language}) {
  let content = {
    English: {
        t1: "Item Distributed",
        t2: "Quantity",
        h1: "Masks",
        h2: "Ration Kits",
        h3: "Sanitizer (100ml)",
        h4: "Food Packets",
        h5: "Soaps",
        h6: "Water Structure",
        h7: "General Support Kits",
   
    },
    Japanese: {
      t1: "供給物",
      t2: "数量",
      h1: "マスク",
      h2: "食糧キット",
      h3: "消毒薬(100ml)",
      h4: "食材パック",
      h5: "ソープ",
      h6: "水の仕組み",
      h7: "一般的な支援キット",
        
        
    }
  };
  language === "Japanese" ? (content = content.Japanese) : (content = content.English);
  return (
    <Table key={i}>
      <Tr class='head'>
      <Td>{content.t1}</Td>
        <Td>{content.t2}</Td>
        </Tr>
        <Tr class='row'>
        <Td>{content.h1}</Td>
          <Td>25000</Td>
        </Tr>
        <Tr class='row'>
        <Td>{content.h2}</Td>
          <Td>11035</Td>
        </Tr>
        <Tr class='row'>
        <Td>{content.h3}</Td>
          <Td>500</Td>
        </Tr>
        <Tr class='row'>
        <Td>{content.h4}</Td>
          <Td>13000</Td>
        </Tr>
        <Tr class='row'>
        <Td>{content.h5}</Td>
          <Td>21000</Td>
        </Tr>
        <Tr class='row'>
        <Td>{content.h6}</Td>
          <Td>31 built</Td>
        </Tr>
        <Tr class='row'>
        <Td>{content.h7}</Td>
          <Td>2066</Td>
        </Tr>
    </Table>
  );
}
