import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'


export const StyledLink = styled(LinkR)`
    text-decoration: none;
    color: #0c0c0c;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #0c0c0c;
    }
`;

export const NGOContentWrap = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 80px;
`

export const NGOContainer = styled.div`
    height: 480px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #010606;

    @media screen and (max-width: 1000px) {
        height: 1600px;
    }

    @media screen and (max-width: 768px) {
        height: 1500px;
    }
`
export const NGOWrapper = styled.div`
    max-width: 1200px;
    margin: -25px auto 25px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    /* padding: 25px 50px; */

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
        max-width: 800px;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 8px 20px;
        max-width: 380px;
    }

`


export const NGOCard = styled.div`
    background: #fefefe;
    display: grid;
    flex-direction: column;
    justify-content: center;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    border-radius: 1rem;
    width: 200px;
    /* border: solid 4px transparent; */
    /* padding: 0.5rem; */
    margin: 10px;
    height: 220px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    &:hover {
        transform: scale(1.04);
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        /* border-color: #D13E1B; */
    }

`

export const NGOIcon = styled.img`
    height: 140px;
    width: 100%;
    object-fit: cover;
    /* margin: 0 auto; */
    
`

export const NGOH1 = styled.h1`
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 75px;
    text-align: center;

    @media screen and (max-width: 480px) {
        font-size: 2rem;
        margin-bottom: 56px;
        text-align: center;

    }
`

export const NGOH2 = styled.h2`
    text-align: center;
    font-size: 1rem;
    margin-left: auto;
    margin-right: auto;
    padding: 2px 5px 2px 5px;

    &:hover {
        color:#D13E1B;
    }
`

export const NGOBtn = styled.nav`
    display: flex;
    align-items: center;
    padding: 20px;
    
    @media screen and (max-width: 768px) {
        padding: 5px;
    }
`

export const ArrowLink = styled.a`
    color: #000;
    font-size: 24px;

    &:hover {
        transition: all 0.2s ease—in—out;
        color: #D13E1B;
    }
`;