import React from 'react'
import { Button } from '../ButtonElement'
import { BtnWrap, Heading, Img, ImgWrap, InfoColumn1, InfoColumn2, InfoContainer, InfoRow, InfoWrapper, StyledLink, Subheading, TextWrapper, Title, Icon, AdvisorTxt, IconWrap, Column, AdvisorInfo, Icon2Wrap } from './TeamElements'
import JsxParser from 'react-jsx-parser'

const img = "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1621994215/tokyo/group-pic_copy_jrtxop.png"

const kihara = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622321651/tokyo/team/Profile_Ai_Kihara-Hunt_rimq69.jpg'
const asia = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1621558990/tokyo/asia-initiatives_e3ymtm.png'
const geeta = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622338402/tokyo/team/download_7_ieww9l.jpg'

const Team = (props) => {
    let content = {
        English: {
            title: "About us",
            headline: "MEET THE TEAM",
            para1: "Tokyo for India is a global student-led initiative supported by a team of more than 30 university students from Japan and India, extending to the USA, the UK, Spain, Australia, Singapore and beyond. We have come together to launch an emergency fundraiser to support and enable critical COVID-relief services for marginalised communities in different parts of rural India.",
            para2: `We are grateful to be holding this fundraiser in partnership with <StyledLink href ='https://asiainitiatives.org'>Asia Initiatives</StyledLink> (AI), a registered NPO in Japan and the USA. AI was <StyledLink href ='https://asiainitiatives.org/our-story/'>founded in Tokyo</StyledLink> and has <StyledLink href ='https://asiainitiatives.org/about/partners/'>partnered</StyledLink> with local non-profit organizations across 20 locations in India. They are doing exemplary work in providing critical relief services and livelihood opportunities since COVID-19 started.`,
            button: "Say Hello",
            a1: "Our Advisors",
            a2: "Our Partner",
            p11: "Prof. Ai Kihara-Hunt",
            p12: "Associate Professor, University of Tokyo",
            p21: "Dr. Geeta Mehta",
            p22: "Founder and President, AI",
            p23: "Adjunct Professor, Columbia University",
            p31: "Asia Initiatives",
            p32: "Registered NPO in Japan and the USA",
            p33: "Founded in Tokyo (1999)",

        },
        Japanese: {
            title: "チーム紹介",
            headline: "チームのご紹介",
            para1: "「Tokyo for India」は日本、インド、アメリカ、イギリス、スペイン、オーストラリア、シンガポールなど様々な国出身の、30人以上の大学生が主導する世界規模の構想です。インドの各地方で周縁化されたコミュニティに対し、必要不可欠なコロナ支援策を手助けするための資金集めをすることを目的に、私たちは集結しました。",
            para2: `このプロジェクトは、日米のNPO法人アジア・イニシアチブズ (Asia Initiatives) の多大なるご尽力があって発足しました。 アジア・イニシアチブズは、東京大学の卒業生でもあるGeeta Mehta博士によって1996年に東京で設立されました。アジア・イニシアチブズは、インドでの20ヶ所で積極的に活動しており、コロナウイルス感染症支援に際して素晴らしい活動を行っているインド全土のローカルNGOとパートナーを結んでいます。`,
            button: "チームのご挨拶",
            a1: "アドバイザー紹介",
            a2: "私たちのパートナー",
            p11: "キハラハント愛",
            p12: "東京大学大学院総合文化研究科「人間の安全保障」プログラム 准教授",
            p21: "Geeta Mehta博士",
            p22: "アジア・イニシアチブズ創設者、社長",
            p23: "コロンビア大学 兼任教授",
            p31: "アジア・イニシアチブズ",
            p32: "日米のNPO法人",
            p33: "1996年に東京で設立されました",

        }
      };
      props.language === "Japanese" ? (content = content.Japanese) : (content = content.English);
    return (
        <>
            <InfoContainer lightBg={false} id={'about'}>
                <InfoWrapper>
                    <InfoRow imgStart={true}>
                        <InfoColumn1>
                            <TextWrapper>
                                <Title>{content.title}</Title>
                                <Subheading>{content.para1}</Subheading>
                                <Subheading><JsxParser jsx={content.para2} components={{ StyledLink }}/></Subheading>
                                <BtnWrap>
                                    <Button to='/team' primary={true ? 1: 0} dark={true ? 1: 0}>{content.button}</Button>
                                </BtnWrap>
                            </TextWrapper>
                        </InfoColumn1>
                        <InfoColumn2>
                        <Heading lightText={true}>{content.headline}</Heading>
                            <ImgWrap>
                                <Img src={img} />
                            </ImgWrap>
                            <InfoRow>
                            <InfoColumn1>
                            <AdvisorTxt>{content.a1}</AdvisorTxt>
                            <IconWrap>
                            <Column>
                            <Icon src ={kihara} />
                            <AdvisorInfo>{content.p11}</AdvisorInfo>
                            <AdvisorInfo>{content.p12}</AdvisorInfo>
                            </Column>
                            <Column>
                            <Icon src ={geeta} />
                            <AdvisorInfo>{content.p21}</AdvisorInfo>
                            <AdvisorInfo>{content.p22}</AdvisorInfo>
                            <AdvisorInfo>{content.p23}</AdvisorInfo>
                            </Column>
                            </IconWrap>
                            </InfoColumn1>
                            <InfoColumn2>
                            <AdvisorTxt>{content.a2}</AdvisorTxt>
                            <Icon2Wrap>
                            <Column>
                            <Icon src ={asia} />
                            <AdvisorInfo>{content.p31}</AdvisorInfo>
                            <AdvisorInfo>{content.p32}</AdvisorInfo>
                            <AdvisorInfo>{content.p33}</AdvisorInfo>
                            </Column>
                            </Icon2Wrap>
                            </InfoColumn2>
                            </InfoRow>
                        </InfoColumn2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
            
        </>
    )
}

export default Team
