import React from 'react';
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink  } from './SidebarElements'

const Sidebar = ({isOpen, toggle, language}) => {
    let content = {
        English: {
            nav1: "NGOs",
            nav2: "Our Mission",
            nav3: "About Us",
            nav4: "Contact Us"

        },
        Japanese: {
            nav1: "支援する団体",
            nav2: "私たちのミッション",
            nav3: "チーム紹介",
            nav4: "問い合わせ"
        }
      };
      language === "Japanese" ? (content = content.Japanese) : (content = content.English);

    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="ngos" onClick={toggle}>{content.nav1}</SidebarLink>
                    <SidebarLink to="mission" onClick={toggle}>{content.nav2}</SidebarLink>
                    <SidebarLink to="about" onClick={toggle}>{content.nav3}</SidebarLink>
                    <SidebarLink to="contact" onClick={toggle}>{content.nav4}</SidebarLink>
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
