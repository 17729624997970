import React from 'react';
import { Table, Tr, Td } from 'react-super-responsive-table';
import './CovidTable.css'

export default function CovidTable({i, language}) {
  let content = {
    English: {
        head1: "Type of Aid",
        head2: "Cost per Kit",
        head3: "Planned No. of Families",
        head4: "Total Cost",
        col1: "Medicine Kits",
        col2: "Ration Kits",
        
    },
    Japanese: {
      head1: "支援種類",
      head2: "1単位の価格",
      head3: "支援予定の家庭数",
      head4: "合計コスト",
      col1: "医薬用品",
      col2: "食糧キット",
    }
  };
  language === "Japanese" ? (content = content.Japanese) : (content = content.English);
  return (
    <Table key={i}>
      <Tr class='head'>
      <Td>{content.head1}</Td>
        <Td>{content.head2}</Td>
        <Td>{content.head3}</Td>
        <Td>{content.head4}</Td>
        </Tr>
        <Tr class='row'>
        <Td>{content.col1}</Td>
          <Td><p>USD 5.47/</p> <p>JPY 600/</p> <p>INR 400</p></Td>
          <Td>250</Td>
          <Td><p>USD 1,400/</p> <p>JPY 140,000/</p> <p>INR 1,00,000</p></Td>
        </Tr>
        <Tr class='row'>
        <Td>{content.col2}</Td>
          <Td><p>USD 20/</p> <p>JPY 2,100/</p> <p>INR 1,500</p></Td>
          <Td>250</Td>
          <Td><p>USD 5,000/</p> <p>JPY 540,000/</p> <p>INR 3,63,000</p></Td>
        </Tr>
    </Table>
  );
}
