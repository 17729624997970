import React from 'react';
import { Table, Tr, Td } from 'react-super-responsive-table';
import './CovidTable.css'

export default function CovidTable({i, language}) {
  let content = {
    English: {
        head1: "Type of Aid",
        head2: "Cost per Kit",
        head3: "No. of Units",
        head4: "Total Cost",
        col1: "Ration Kits",
        col2: "Sanitation Kits",
        col3: "Medicine Kits",
        col4: "Seeds/Farm Implements",
        col5: "Menstrual Health Resources",
        col6: "Immunity Booster Kits",
        
    },
    Japanese: {
      head1: "支援種類",
      head2: "1単位の価格",
      head3: "キットの数",
      head4: "合計コスト",
      col1: "食糧キット",
      col2: "衛生キット",
      col3: "医薬用品",
      col4: "種子・農機具",
      col5: "月経健康リソース",
      col6: "免疫のブースターキット",
    }
  };
  language === "Japanese" ? (content = content.Japanese) : (content = content.English);
  return (
    <Table key={i}>
      <Tr class='head'>
      <Td>{content.head1}</Td>
        <Td>{content.head2}</Td>
        <Td>{content.head3}</Td>
        <Td>{content.head4}</Td>
        </Tr>
        <Tr class='row'>
        <Td>{content.col1}</Td>
          <Td><p>USD 3.3/</p> <p>JPY 370/</p> <p>INR 220</p></Td>
          <Td>157</Td>
          <Td><p>USD 500/</p> <p>JPY 60,000/</p> <p>INR 36,000</p></Td>
        </Tr>
        <Tr class='row'>
        <Td>{content.col2}</Td>
          <Td><p>USD 4/</p> <p>JPY 450/</p> <p>INR 290</p></Td>
          <Td>200</Td>
          <Td><p>USD 800/</p> <p>JPY 90,000/</p> <p>INR 58,000</p></Td>
        </Tr>
        <Tr class='row'>
        <Td>{content.col3}</Td>
          <Td><p>USD 32/</p> <p>JPY 3,500/</p> <p>INR 2,300</p></Td>
          <Td>20</Td>
          <Td><p>USD 640/</p> <p>JPY 70,000/</p> <p>INR 46,000</p></Td>
        </Tr>
        <Tr class='row'>
        <Td>{content.col4}</Td>
          <Td><p>USD 4/</p> <p>JPY 450/</p> <p>INR 290</p></Td>
          <Td>220</Td>
          <Td><p>USD 850/</p> <p>JPY 90,000/</p> <p>INR 61,000</p></Td>
        </Tr>
        <Tr class='row'>
        <Td>{content.col5}</Td>
          <Td><p>USD 2.5/</p> <p>JPY 270/</p> <p>INR 180</p></Td>
          <Td>220</Td>
          <Td><p>USD 540/</p> <p>JPY 50,000/</p> <p>INR 39,000</p></Td>
        </Tr>
        <Tr class='row'>
        <Td>{content.col6}</Td>
          <Td><p>USD 3/</p> <p>JPY 370/</p> <p>INR 210</p></Td>
          <Td>250</Td>
          <Td><p>USD 750/</p> <p>JPY 70,000/</p> <p>INR 54,000</p></Td>
        </Tr>
    </Table>
  );
}
