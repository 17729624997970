export const NGO1Data = {
    id: 'ngo1',
    iconSrc: 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1621558990/tokyo/ngo1_obs0aw.png',
    card1: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1621559055/tokyo/ngo1/icon1_nsnr1i.png",
    card2: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1621559054/tokyo/ngo1/icon2_qct8bf.png",
    card3: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1621559054/tokyo/ngo1/icon3_grlpxl.png",
    card4: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1621559054/tokyo/ngo1/icon4_re3c8w.png",
    map: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1621559031/tokyo/ngo1/map_clwbd2.png",
    awards: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1621559032/tokyo/ngo1/awards_igib9o.png",
};

export const NGO2Data = {
    id: 'ngo2',
    iconSrc: 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1621558991/tokyo/ngo2_ajkj81.jpg',
    card1: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622051708/tokyo/ngo3/icons-03_zgpga8.png",
    card2: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622051708/tokyo/ngo3/icons-01_v2ohj6.png",
    card3: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622051708/tokyo/ngo3/icons-02_xivzcd.png",
    card4: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1621559054/tokyo/ngo1/icon4_re3c8w.png",
    map: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622055648/tokyo/ngo2/IMG_1012_d7ryyk.png",
    awards: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622055491/tokyo/ngo2/Final_partners_psgl4b.png",
};

export const NGO3Data = {
    id: 'ngo3',
    iconSrc: 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1621558990/tokyo/ngo3_rmo1sc.jpg',
    card1: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622052609/tokyo/ngo3/6_P_s_faexgg.jpg",
    card2: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622052611/tokyo/ngo3/6_R_s_cnrieq.jpg",
    card3: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622051708/tokyo/ngo3/icons-02_xivzcd.png",
    card4: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1621559054/tokyo/ngo1/icon4_re3c8w.png",
    map: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622387990/tokyo/ngo3/map_undkdp.jpg",
    awards: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622038469/tokyo/ngo3/awards_z57agq.jpg",
};

export const NGO4Data = {
    id: 'ngo4',
    iconSrc: 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1621558990/tokyo/ngo5_bkbgih.jpg',
    card1: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1621736542/tokyo/ngo5/1_owtbmc.jpg",
    card2: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1621736542/tokyo/ngo5/2_ajrqyl.jpg",
    card3: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1621736542/tokyo/ngo5/3_qackph.jpg",
    card4: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1621736543/tokyo/ngo5/4_oskolz.jpg",
    map: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622039160/tokyo/ngo5/map-1_qnxc13.jpg",
    awards: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622039242/tokyo/ngo5/awards_a6npqa.jpg",
};

export const NGO5Data = {
    id: 'ngo5',
    iconSrc: 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1621648755/tokyo/ngo4_odfp8e.png',
    card1: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622055241/tokyo/ngo4/Response_oexe3f.png",
    card2: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622055241/tokyo/ngo4/Resilience_dus5ky.png",
    card3: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1621559054/tokyo/ngo1/icon3_grlpxl.png",
    card4: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1621559054/tokyo/ngo1/icon4_re3c8w.png",
    map: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622038789/tokyo/ngo4/Map_aud1ey.png",
    awards: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1621731550/tokyo/ngo4/partners_y9ek9u.png",
};
