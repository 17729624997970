import React from 'react'
import { NGOHeaderWrap, NGOIcon, NGOInfo, NGOName, NGOPageContainer1, NGOPageContainer2, NGOLeft, NGORight, NGOContentColumn1,NGOContentColumn2, NGOCovidInfoH1, NGOCovidInfop, NGOCardWrap, NGOCovidCard, NGOPageContainer3, NGOMap, NGOPageContainer4,  NGOAwards, NGOQuote, NGOPageContainer5, NGOPageContainer6, NGOPageContainer7, Red } from './NGOPageElements'
import CovidTable from './CovidTable'
import AboutTable from './AboutTable'
import ItemTable from './ItemTable'
import Slideshow from './Slideshow'
import JsxParser from 'react-jsx-parser'
import './styles.css'

const NGOPage = ({ iconSrc, card1, card2, card3, card4, map, awards, language }) => {
    let content = {
        English: {
            head1: "Community served: Women",
            head2: "Area of operation: 500 villages across 4 districts of Rajasthan",
            head3: "Estimated cost of COVID relief project: USD 6,000/ JPY 650,000/ INR 4,35,000",
            title1: "CURRENT COVID RELIEF PROPOSAL",
            para1: `GMVS is distributing <Red>ration kits</Red> & <Red>sanitation kits</Red> and combining this with a <Red>door-to-door awareness campaign.</Red> This is essential in Indian villages, <a href= "https://www.indiatoday.in/coronavirus-outbreak/story/government-sop-covid-rural-india-1803175-2021-05-16">where</a> a lack of awareness related to COVID is leading to late diagnosis and in many cases, fatalities.`,
            para2: `Our support of USD 6,000/ JPY 650,000/ INR 4,35,000 could help GMVS reach <Red>500 families.</Red>`,
            title2: "OVERVIEW",
            para3: "In 1998, GMVS started out with the aim to mobilise rural society and support their livelihood. Today, their focus areas include:",
            para4: "• Formation of <Red>SHG (Self Help Groups)</Red> for women and girls",
            para5: "• Providing access to quality <Red>healthcare and education</Red> for people in poverty",
            para6: "• Imparting <Red>managerial skills</Red> to women for income generation and employment",
            title3: "ABOUT GMVS",
            title4: "EFFORTS SINCE MARCH 2020",
            para7: `In addition to sanitation kits and medical facilities, GMVS provided <a href ="https://indianexpress.com/article/india/the-long-walk-of-indias-migrant-workers-in-covid-hit-2020-7118809/">migrant workers</a> with essential supplies. Their contribution towards COVID relief was recognised by the International Human Rights Advisory Council and numerous national awards.`,
            title5: "AWARDS & MEDIA COVERAGE",
            para8: `GMVS’ work has been <a href="http://www.gmvs.org.in/downloads/in-media">covered</a> in India’s largest national daily—<a href="https://www.bhaskar.com/RAJ-AJM-OMC-MAT-latest-ajmer-news-042004-1190188-NOR.html/">Dainik Bhaskar</a> (2016)—and many regional newspapers like Dainik Navjyoti and Rajasthan Patrika.`,
            quote: `<blockquote><p>I got stuck here during the lockdown with nothing but the NGO provided us with pulses, rice and helped us a lot.</p><footer>Bhera Ram, migrant worker</footer></blockquote>`

            
        },
        Japanese: {
            head1: "担当するコミュニティ：女性",
            head2: "活動地域：ラジャスタンの4地区にまたがる、500の村々",
            head3: "コロナ支援策の想定される費用：USD 6,000/ JPY 650,000/ INR 4,35,000",
            title1: "現在のコロナ支援案",
            para1: `GMVSは<Red>配給キット</Red>と<Red>衛生キット</Red>を配っている他、<Red>戸別の意識向上キャンペーン</Red>を行っています。これは<a href ='https://www.indiatoday.in/coronavirus-outbreak/story/government-sop-covid-rural-india-1803175-2021-05-16'>インドの村々</a>では欠かせません。コロナの意識不足が診断の遅れや、多くの場合、死に至っているからです。`,
            para2: `私たちが65万円分サポートすることで、GMVSは<Red>500世帯</Red>に支援の手を差し伸べることができます。`,
            title2: "概要",
            para3: "1988年、GMVSは田舎社会の流動化や生計支援という目的で活動をスタートしました。今では、GMVSが力を入れる分野は次のようになっています。",
            para4: `• <Red>SHG（自助グループ）</Red>を女性、女児のために設立すること`,
            para5: `• 質の高い<Red>医療と教育</Red>を、貧困層に提供する`,
            para6: `• 収入増や職業機会のため、<Red>経営者スキル</Red>を女性に与えること`,
            title3: "GMVSについて",
            title4: "2020年3月以降の活動成果",
            para7: `衛生物資や医療設備に加え、GMVSは<a href ="https://indianexpress.com/article/india/the-long-walk-of-indias-migrant-workers-in-covid-hit-2020-7118809/">移民労働者</a>に必要物資を提供しました。COVID支援に対するGMVSの貢献は国際人権諮問委員会にも認められた他、国内で幾多の賞を受賞しています。`,
            title5: "受賞とメディアでの報道",
            para8: `GMVSの業績はインド最大の日刊紙「<a href ='https://www.bhaskar.com/RAJ-AJM-OMC-MAT-latest-ajmer-news-042004-1190188-NOR.html/'>ダイニック・バスカー</a>」にも<a href ='http://www.gmvs.org.in/downloads/in-media'>報道</a>されたました。加えて、2018年にはラジャスタン・パトリカ、2019年にはダイニック・ナビョティといった多くの地域新聞社の報道も受けました。`,
            quote: `<blockquote><p>ロックダウンになって、何も持ち合わせがないままじっとしていないといけなかった。でもGMVSが豆とか米をくれて、たくさん助けてくれたよ</p><footer> ベラ・ラム（移民労働者）</footer></blockquote>`
        }
      };
      language === "Japanese" ? (content = content.Japanese) : (content = content.English);
    return (
        <>
            <NGOPageContainer1>
                <NGOHeaderWrap>
                    <NGOLeft>
                        <NGOIcon src={iconSrc}/>
                    </NGOLeft>
                    <NGORight>
                        <NGOName>Gramin Mahila Vikas Sansthan (GMVS)</NGOName>
                        <NGOInfo>{content.head1}</NGOInfo>
                        <NGOInfo>{content.head2}</NGOInfo>
                        <NGOInfo>{content.head3}</NGOInfo>
                    </NGORight>
                </NGOHeaderWrap>
            </NGOPageContainer1>

            <NGOPageContainer2>
                    <NGOContentColumn1>
                        <NGOCovidInfoH1><JsxParser jsx={content.title1}/></NGOCovidInfoH1>
                        <NGOCovidInfop><JsxParser jsx={content.para1} components={{ Red }}/></NGOCovidInfop>
                        <NGOCovidInfop><JsxParser jsx={content.para2} components={{ Red }}/></NGOCovidInfop>
                        <CovidTable language={language}/>
                    </NGOContentColumn1>
                    <NGOContentColumn2>
                        <NGOCardWrap>
                            <NGOCovidCard src ={card1}/>
                            <NGOCovidCard src ={card2}/>
                            <NGOCovidCard src ={card3}/>
                            <NGOCovidCard src ={card4}/>
                        </NGOCardWrap>
                    </NGOContentColumn2>
            </NGOPageContainer2>

            <NGOPageContainer3>
                    <NGOContentColumn1>
                    <NGOCovidInfoH1>{content.title2}</NGOCovidInfoH1>
                        <NGOCovidInfop>{content.para3}</NGOCovidInfop>
                        <NGOCovidInfop><JsxParser jsx={content.para4} components={{ Red }}/></NGOCovidInfop>
                        <NGOCovidInfop><JsxParser jsx={content.para5} components={{ Red }}/></NGOCovidInfop>
                        <NGOCovidInfop><JsxParser jsx={content.para6} components={{ Red }}/></NGOCovidInfop>
                    </NGOContentColumn1>
                    <NGOContentColumn2>
                    <Slideshow language={language}/>
                    </NGOContentColumn2>
            </NGOPageContainer3>

            <NGOPageContainer4>
                    <NGOContentColumn1>
                        <NGOCovidInfoH1>{content.title3}</NGOCovidInfoH1>
                        <AboutTable language={language}/>
                    </NGOContentColumn1>
                    <NGOContentColumn2>
                        <NGOMap src = {map}/>
                        
                    </NGOContentColumn2>
            </NGOPageContainer4>

            <NGOPageContainer5>
                    <NGOContentColumn1>
                    <NGOCovidInfoH1>{content.title4}</NGOCovidInfoH1>
                    <NGOCovidInfop><JsxParser jsx={content.para7}/></NGOCovidInfop>
                    </NGOContentColumn1>
                    <NGOContentColumn2>
                        <ItemTable language={language}/>
                    </NGOContentColumn2>
            </NGOPageContainer5>

            <NGOPageContainer6>
                <NGOContentColumn1>
                    <NGOCovidInfoH1>{content.title5}</NGOCovidInfoH1>
                    <NGOCovidInfop><JsxParser jsx={content.para8}/></NGOCovidInfop>
                    </NGOContentColumn1>
                    <NGOContentColumn2>
                    <NGOQuote><JsxParser jsx={content.quote}/></NGOQuote>
                </NGOContentColumn2>
                
            </NGOPageContainer6>
            <NGOPageContainer7> <NGOAwards src={awards}/></NGOPageContainer7>
           
    </>
    )
}

export default NGOPage
