import React from 'react'
import { PhotoContent, PhotoContainer, PhotoBg, Heading, Container1,Container2, Subheading, ImgWrap, Icon, Map, V1Wrap, V2Wrap, StyledLink, Column, Caption } from './MissionPageElements'
import YouTube from 'react-youtube';
import JsxParser from 'react-jsx-parser'

const poster = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622443521/tokyo/mission/poster_ftsqhl.png'
const img1 = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622404580/tokyo/mission/image_3_q9w5ei.jpg'
const img2 = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622421642/tokyo/mission/COVID_in_villages_se4z40.jpg'


const MissionPage = (props) => {
    const opts1 = {
        height: '350',
        width: '600',
    }
    const opts2 = {
        height: '200',
        width: '320',
    }
    let content = {
        English: {
            title: "Our Mission",
            para1: `The second wave of COVID-19 in India has been devastating. While official figures show that <StyledLink href ='https://www.nytimes.com/article/india-coronavirus-cases-deaths.html'>250,000</StyledLink> people had died by mid May, the actual figures are <StyledLink href ='https://www.npr.org/sections/goatsandsoda/2021/04/30/992451165/india-is-counting-thousands-of-daily-covid-deaths-how-many-is-it-missing'>estimated to be</StyledLink> 3-5 times as high.`,
            para2: `The pandemic is only now <StyledLink href ='https://www.japantimes.co.jp/news/2021/05/18/asia-pacific/covid-rural-india/'>reaching villages</StyledLink> and marginalised communities, who have almost <StyledLink href ='https://edition.cnn.com/2021/05/11/india/india-covid-gujarat-village-intl-hnk/index.html'>no access to doctors and hospitals.</StyledLink> These communities lived in a precarious state even before the pandemic began and are now suffering a humanitarian crisis in parallel with the medical emergency that has taken over the nation.`,
            para3: 'We have launched this fundraiser as a call to action for all globally-minded citizens. Together with NPO Asia Initiatives, we will distribute the funds raised to five local NGOs undertaking COVID-relief activities for marginalised populations in different parts of rural India. If we reach our goal before the end of the campaign, we will expand the initiative to support other marginalised populations in different parts of rural India.',
            para4: `AI has <StyledLink href ='https://asiainitiatives.org/about/partners/'>partnered</StyledLink> with local non-profit organisations across 20 locations in India and has been doing exemplary work in providing critical relief services and livelihood opportunities since the COVID-19 pandemic started.`,
            para5: 'We are calling this Tokyo for India because this project was born out of the collaboration of friends in Japan and India, but this campaign is meant to be about globally-minded individuals from around the world coming together to help India. So, from whichever part of the world you are visiting our campaign, we sincerely thank you for visiting our Tokyo for India campaign.',
            para6: 'Our fundraiser will be successful if we can even help one person, or save one life. And we assure you that each contribution from you will do just that.',
            para7: 'Donate. Spread the word. Stay safe, and stay healthy.',
            cap1: `Recently, mass cremations have become a common feature in India due to a spike in deaths caused by COVID-19. (Source: <StyledLink href ='https://www.npr.org/sections/goatsandsoda/2021/04/30/992451165/india-is-counting-thousands-of-daily-covid-deaths-how-many-is-it-missing'>NPR</StyledLink>) `,
            cap2: `A makeshift open-air clinic for COVID-19 patients in a village in India. (Source: <StyledLink href ='https://www.reuters.com/world/india/under-tree-one-indian-village-cares-its-covid-19-sick-2021-05-17/'>Reuters</StyledLink>) `,
            map: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1621785378/tokyo/map_english_vqeuue.png",

        },
        Japanese: {
            title: "私たちのミッション",
            para1: `インドではコロナウイルスの第二波は猛威を振るっています。今年3月中旬までに、およそ<StyledLink href='https://jp.reuters.com/article/india-covid19-12-idJPKBN2CT0BM'>25万</StyledLink>人の方が亡くなったと公表されていますが、<StyledLink href ='https://www.cnn.co.jp/world/35170060.html'>実際に</StyledLink>は最低でもこの数の<StyledLink href ='https://jp.reuters.com/article/health-coronavirus-india-idJPKCN2D015G'>3~5倍</StyledLink>を超えていると推測されています。`,
            para2: `新型コロナウイルスは、<StyledLink href ='https://www3.nhk.or.jp/news/html/20210510/k10013020861000.html'>医師や病院へのアクセスが困難</StyledLink>な村や、社会の周縁に追いやられた<StyledLink href ='https://www.afpbb.com/articles/-/3346788'>地域にまで急速に広がっています。</StyledLink>これらの地域にあるコミュニティは、コロナウイルスが始まる前から不安定な状態で生活しており、現在、インド全国を振るった医療緊急事態と並行して、人道的危機に陥っています。`,
            para3: '私たちは全ての国際的視野を持つ市民への行動喚起としてこの募金活動に着手しています。NPO法人アジア・イニシアチブズとともに、インドのさまざまな地域で社会から取り残された人々のために、コロナ支援策を行っている5つのNGOに寄付金を分配していきます。このキャンペーンの終わりまでに目標に達した場合、他のインドのさまざまな地域で社会から取り残された人々を支援する取り組みを広げます。',
            para4: `アジア・イニシアチブズは、インドでの20ヶ所で<StyledLink href ='https://asiainitiatives.org/about/partners/'>積極的に活動しており、</StyledLink>コロナウイルス感染症支援に際して<StyledLink href ='https://www.youtube.com/watch?v=q6qg4_q8VC8'>素晴らしい活動</StyledLink>を行っているインド全土のローカルNGOとパートナーを結んでいます。`,
            para5: 'このプロジェクトが日本とインドの友人の協力から生まれたために、『Tokyo for India』という名前ですが、このプロジェクトの本質は、世界中の国際志向の人々が団結してインドを助けることです。なので、このプロジェクトご覧になっているあなたがどこ出身の方でも、私たち「Tokyo for India」のホームページを訪れていただいたことに心から感謝いたします。',
            para6: '一人の人、一つの命でも救うことができたならば、我々のプロジェクトは成功したと言えるでしょう。そしてそれには皆さんのご協力が必要なのです。',
            para7: '支援しよう。言葉を広めよう。健康で、お大事にしましょう。',
            cap1: `R新型コロナウイルスによる死者が急増したことにより、火葬が非常に多くなっています。[出典: <StyledLink href ='https://www.npr.org/sections/goatsandsoda/2021/04/30/992451165/india-is-counting-thousands-of-daily-covid-deaths-how-many-is-it-missing'>NPR]</StyledLink>) `,
            cap2: `インドの村にあるコロナ患者のための仮設野外診療所。[出典:<StyledLink href ='https://www.reuters.com/world/india/under-tree-one-indian-village-cares-its-covid-19-sick-2021-05-17/'> Reuters]</StyledLink>) `,
            map: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622847709/tokyo/map_japanese_alftpw.png",
        }
      };
      props.language === "Japanese" ? (content = content.Japanese) : (content = content.English);
    return (
        <>
            <PhotoContainer>
            <PhotoContent>
            <Heading>{content.title}</Heading>
            </PhotoContent>
            </PhotoContainer>
            <Container1>
            <PhotoContent>
            <PhotoBg src={poster}/>
            </PhotoContent>
            </Container1>
            <Container2>
            <Subheading><JsxParser jsx={content.para1} components={{ StyledLink }}/></Subheading>
            <Subheading><JsxParser jsx={content.para2} components={{ StyledLink }}/></Subheading>
            <ImgWrap>
            <Column>
            <Icon src ={img1} />
            <Caption><JsxParser jsx={content.cap1} components={{ StyledLink }}/></Caption>
            </Column>
            <Column>
            <Icon src ={img2} />
            <Caption><JsxParser jsx={content.cap2} components={{ StyledLink }}/></Caption>
            </Column>
            </ImgWrap>
            <Subheading>{content.para3}</Subheading>
            <Map src={content.map}/>
            <Subheading><JsxParser jsx={content.para4} components={{ StyledLink }}/></Subheading>
            <V1Wrap>
            <YouTube videoId="q6qg4_q8VC8" opts ={opts1} />
            </V1Wrap>
            <V2Wrap>
            <YouTube videoId="q6qg4_q8VC8" opts ={opts2} />
            </V2Wrap>
            <Subheading>{content.para5}</Subheading>
            <Subheading>{content.para6}</Subheading>
            <Subheading></Subheading>
            <Subheading></Subheading>
            <Subheading><i>{content.para7}</i></Subheading>
            </Container2>

        </>
    )
}

export default MissionPage
