import React from 'react'
import { FaBars } from 'react-icons/fa'
import { Nav,NavbarContainer, NavLogo, HamburgerIcon, NavMenu, NavItem, NavLinks, HomeLogo, NavbarDropdown, NavbarDropdownContent, StyledLink, LangSwitcher, NavSuper } from './NavbarElements'
import { animateScroll as scroll } from 'react-scroll'

const Navbar = (props) => {
    let content = {
        English: {
            title: "Home",
            nav1: "NGOs",
            nav2: "Our Mission",
            nav3: "About Us",
            nav4: "Contact Us"

        },
        Japanese: {
            title: "ホメパゲ",
            nav1: "支援する団体",
            nav2: "私たちのミッション",
            nav3: "チーム紹介",
            nav4: "問い合わせ"
        }
      };

    const toggleHome = () => {
        scroll.scrollToTop();
    }
    props.language === "Japanese" ? (content = content.Japanese) : (content = content.English);

    return (
        <>
            <Nav>
                <NavbarContainer>
                <NavSuper>
                <HomeLogo to="/" onClick={toggleHome}>
                    <NavLogo>{content.title}</NavLogo>
                    </HomeLogo>
                    <LangSwitcher
                                value={props.language}
                                onChange={e => props.handleSetLanguage(e.target.value)}>
                                <option value="English">English</option>
                                <option value="Japanese">日本語</option>
                    </LangSwitcher>
                    </NavSuper>
                    <HamburgerIcon onClick={props.toggle}>
                        <FaBars />
                    </HamburgerIcon>
                    <NavMenu>
                    
                    <NavbarDropdown>
                        <NavItem>
                            <NavLinks to="/" smooth={true} duration={500} spy={true} exact='true' offset={-60}>{content.nav1}</NavLinks>
                        </NavItem>
                        <NavbarDropdownContent>
                            <StyledLink to= "/gmvs">GMVS</StyledLink>
                            <StyledLink to= "/hrds">HRDS</StyledLink>
                            <StyledLink to= "/ingon">INGON</StyledLink>
                            <StyledLink to= "/parmarth">Parmarth</StyledLink>
                            <StyledLink to= "/samanta">Samanta</StyledLink>
                        </NavbarDropdownContent>
                    </NavbarDropdown>
                        <NavItem>
                            <NavLinks to="/mission" smooth={true} duration={500} spy={true} exact='true' offset={-60}>{content.nav2}</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="/team" smooth={true} duration={500} spy={true} exact='true' offset={-60}>{content.nav3}</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="/contact" smooth={true} duration={500} spy={true} exact='true' offset={-60}>{content.nav4}</NavLinks>
                        </NavItem>
                    </NavMenu>
                </NavbarContainer>
            </Nav>
        </>
    );
};

export default Navbar
