import styled from 'styled-components'

export const TaxButton = styled.button`
    background: transparent;
    color: white;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 25px;
    padding: 8px;
    border-radius: 18px;

    @media screen and (max-width:768px) {
        font-size: 14px;
    }
`

export const Icon = styled.span`
    font-size: 18px;
    /* padding: 5px; */
    display: flex-inline;
    /* vertical-align: bottom; */

    @media screen and (max-width:768px) {
        font-size: 13px;
    }
`
export const TaxP = styled.p`
    margin-bottom: 35px;
    font-size: 20px;
    line-height: 24px;
    color: #010606;
    @media screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 20px;

    }
`;

export const TaxH = styled.h1`
    margin-bottom: 24px;
    font-size: 28px;
    line-height: 1.1.%;
    color: #010606;
    font-weight: bold;

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: center;
    margin: 40px;
    @media screen and (max-width: 480px) {
        margin: 0;
    }

`

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
`

export const Img = styled.img`
    width: 300px;

@media screen and (max-width: 480px) {
        display: none;
    }

`
export const Bold = styled.span`
    font-size: 16px;
    /* line-height: 24px; */
    font-weight: bold;
    color: white;

    @media screen and (max-width:768px) {
        font-size: 16px;
    }
`;
