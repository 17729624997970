import React from 'react';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

const Slideshow = (props) => {
  let content = {
    English: {
      images :[
        {
          src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621721181/tokyo/ngo2/4_ldbeyx.png',
          alt: 'Founder Mr. Aji Krishnan(founder) conversing with children'
        },
        {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621724758/tokyo/ngo2/1.1_jpt7du.jpg',
        alt:'Our researcher, Swetha Soundararajan conversing with the administrator of HRDS, Mrs. Gayatri Varma'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621721181/tokyo/ngo2/2_mserua.png',
        alt: 'Food kits supplied by HRDS in May 2020'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621724571/tokyo/ngo2/3.1_yfjodh.jpg',
        alt: 'Awareness program conducted by students of green school'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621721181/tokyo/ngo2/5_ishces.jpg',
        alt: 'HRDS being awarded Baba Sahib National Award in 2016'
      },
    ]
        
    },
    Japanese: {
      images: [
        {
          src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621721181/tokyo/ngo2/4_ldbeyx.png',
          alt: 'Aji Krishnan （創設者）と子供たちとの会話'
        },
        {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621724758/tokyo/ngo2/1.1_jpt7du.jpg',
        alt:'当方の調査員Swetha SoundararajanとHRDSの理事のGayatri Varmaとの対談'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621721181/tokyo/ngo2/2_mserua.png',
        alt: '2020年5月にHRDSによって供給された食糧キット'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621724571/tokyo/ngo2/3.1_yfjodh.jpg',
        alt: 'green schoolの生徒によって行われた意識向上活動'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621721181/tokyo/ngo2/5_ishces.jpg',
        alt: 'HRDSが2016年にBaba Sahib National Awardを受賞'
      },
    ]
    }
  };
  props.language === "Japanese" ? (content = content.Japanese) : (content = content.English);

  return (
    <Carousel images={content.images} style={{ height: 300, width: 400 }} isLoop={true} hasThumbnails={false} hasThumbnailsAtMax={true} isAutoPlaying={true} autoPlayInterval={4000} hasMediaButton={false} hasIndexBoard={false} hasCaptions={'bottom'} hasLeftButton={true} hasRightButton={true} hasSizeButton={'topRight'} widgetsHasShadow={true}/>
  );
};

export default Slideshow;