import React, { useState } from 'react'
import { Axios, db } from '../../../firebase/firebaseConfig'
import './styled.scss'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';


const ContactForm = (props) => {
  let content = {
    English: {
        name: "Name",
        email: "Email",
        msg: "Message",
        submit: "Submit",

    },
    Japanese: {
      name: "名前",
      email: "eメール",
      msg: "メッセージ",
      submit: "送る",
    }
  };
  const [formData, setFormData] = useState({})

  const toastifySuccess = () => {
    toast.success('Thank You!', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
  };

  const toastifyFail = () => {
    toast.error('Please try again later!', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
  };

  const updateInput = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }
  const handleSubmit = event => {
    event.preventDefault()
    if (formData.name !== null & formData.name !== null & formData.name !== null ){
    sendEmail()
    sendSlack()
    setFormData({
      name: '',
      email: '',
      message: '',
    })
}
  }
  const sendEmail = () => {
    Axios.post(
      'https://us-central1-tokyo-for-india.cloudfunctions.net/submit',
      formData
    )
      .then(res => {
        db.collection('contact-form').add({
          name: formData.name,
          email: formData.email,
          message: formData.message,
          time: new Date(),
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  async function sendSlack () {

    const webhookUrl = 'https://hooks.slack.com/services/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';

    const data = {
        "text": `*Name:* ${formData.name} \n *Email:* ${formData.email} \n *Message:* ${formData.message}`,
    }

    let res = await Axios.post(webhookUrl, JSON.stringify(data), {
        withCredentials: false,
        transformRequest: [(data, headers) => {
            delete headers.post["Content-Type"]
            return data
        }]
    })

    if (res.status === 200) {
        toastifySuccess();
    } else {
        toastifyFail();
    }
}
props.language === "Japanese" ? (content = content.Japanese) : (content = content.English);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder={content.name}
          onChange={updateInput}
          value={formData.name || ''}
          required
        />
        <input
          type="email"
          name="email"
          placeholder={content.email}
          onChange={updateInput}
          value={formData.email || ''}
          required
        />
        <textarea
          rows={3}
          type="text"
          name="message"
          placeholder={content.msg}
          onChange={updateInput}
          value={formData.message || ''}
          required
        ></textarea>
        <button type="submit">{content.submit}</button>
      </form>
      <div><ToastContainer /></div>
    </>
  )
}

export default ContactForm