import React from 'react';
import { Table, Tr, Td } from 'react-super-responsive-table';
import './CovidTable.css'

export default function ItemTable({i, language}) {
  let content = {
    English: {
        t1: "Item Distributed",
        t2: "Quantity",
        h1: "Masks",
        h2: "Sanitizers",
        h3: "Oxymeters",
        h4: "Thermometers",
        h5: "Soaps",
        h6: "Sanitary Pads",
        h7: "Home Learning Kits",
   
    },
    Japanese: {
      t1: "供給物",
      t2: "数量",
      h1: "マスク",
      h2: "消毒薬",
      h3: "酸素濃度計",
      h4: "温度計",
      h5: "ソープ",
      h6: "生理用ナプキン",
      h7: "家庭学習用キット",
        
        
    }
  };
  language === "Japanese" ? (content = content.Japanese) : (content = content.English);
  return (
    <Table key={i}>
      <Tr class='head'>
      <Td>{content.t1}</Td>
        <Td>{content.t2}</Td>
        </Tr>
        <Tr class='row'>
        <Td>{content.h1}</Td>
          <Td>1000</Td>
        </Tr>
        <Tr class='row'>
        <Td>{content.h2}</Td>
          <Td>500</Td>
        </Tr>
        <Tr class='row'>
        <Td>{content.h3}</Td>
          <Td>10</Td>
        </Tr>
        <Tr class='row'>
        <Td>{content.h4}</Td>
          <Td>10</Td>
        </Tr>
        <Tr class='row'>
        <Td>{content.h5}</Td>
          <Td>2000</Td>
        </Tr>
        <Tr class='row'>
        <Td>{content.h6}</Td>
          <Td>750</Td>
        </Tr>
        <Tr class='row'>
        <Td>{content.h7}</Td>
          <Td>5000</Td>
        </Tr>
    </Table>
  );
}
