import styled from 'styled-components'

export const ErrorContainer = styled.div`
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0px;
    height: 100%;
    width: auto;
    position: relative;
    z-index: 1;

    @media screen and (max-width:480px) {
        width: 100%;
        height: auto;
    }
`;

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    --o-object-fit: cover;
    object-fit: cover;
    filter: brightness(40%);
`;

export const ErrorContent = styled.div`
 z-index: 3;
 /* max-width: 1200px; */
 position: absolute;
 /* padding: 8px 24px; */
 display: flex;
 flex-direction: column;
 align-items: center;
`;

export const ErrorH1 = styled.h1`
    color: #fff;
    font-size: 5rem;
    text-align: center;

    @media screen and (max-width:768px) {
        font-size: 3rem;
    }

    @media screen and (max-width:480px) {
        font-size: 3rem;
    }
`;

export const ErrorP = styled.p`
    margin-top: 24px;
    color: #fff;
    font-size: 24px;
    text-align: center;
    max-width: 600px;

    @media screen and (max-width:768px) {
        font-size: 24px;
    }

    @media screen and (max-width:480px) {
        font-size: 18px;
    }
`;

export const ErrorBtnWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;

`;