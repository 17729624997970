import React from 'react';
import { Table, Tr, Td, Th } from 'react-super-responsive-table';
import './AboutTable.css'

export default function AboutTable(props) {
  let content = {
    English: {
        h1: "Name of the Organization",
        h2: "Key person of the institution",
        h3: "Years in Existence",
        h4: "Postal Address",
        h5: "Email",
        h6: "Website",
        h7: "Contact Number",
        h8: "Society Registration Details",
        h9: "FCRA Registration Details",
        h10: "TAN Number",
        h11: "PAN Number",
        p1: "HRDS India (The Highrange Rural Development Society)",
        p2: "Aji Krishnan (Founder-Secretary)",
        
        
    },
    Japanese: {
      h1: "団体名",
      h2: "団体の代表",
      h3: "団体の活動期間",
      h4: "住所",
      h5: "メールアドレス",
      h6: "ホームページ",
      h7: "電話番号",
      h8: "社会登録番号",
      h9: "FCRA番号",
      h10: "TAN番号",
      h11: "PAN番号",
      p1: "HRDS　India（高域の農村開発共同体）",
      p2: "Aji Krishnan　（設立者、幹事）",
    }
  };
  props.language === "Japanese" ? (content = content.Japanese) : (content = content.English);
  return (
    <Table>
      <Tr>
        <Th class='type'>{content.h1}</Th>
        <Td bgcolor='#fefefe'>{content.p1}</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h2}</Th>
        <Td bgcolor='#fefefe'>{content.p2}</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h3}</Th>
        <Td bgcolor='#fefefe'>23</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h4} </Th>
        <Td bgcolor='#fefefe'>Telephone Exchange Road, Thodupuzha, Idukki Dist, Kerala - 685585, India.</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h5} </Th>
        <Td bgcolor='#fefefe'>mail@hrdsindia.org</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h6} </Th>
        <Td bgcolor='#fefefe'><a href ="https://hrdsindia.org/index.php ">https://hrdsindia.org/index.php</a></Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h7} </Th>
        <Td bgcolor='#fefefe'>0491-2572576, +91-9447025474</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h8}</Th>
        <Td bgcolor='#fefefe'>Telephone Exchange Road, Thodupuzha, Idukki Dist, Kerala - 685585, India.</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h9}</Th>
        <Td bgcolor='#fefefe'>FCRA No. 052890109</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h11}</Th>
        <Td bgcolor='#fefefe'>AAAJH0168A</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h10}</Th>
        <Td bgcolor='#fefefe'>CHNH00739E</Td>
        </Tr>
    </Table>
  );
}
