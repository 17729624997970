import React from 'react';
import { Table, Tr, Td } from 'react-super-responsive-table';
import './CovidTable.css'

export default function CovidTable({i, language}) {
  let content = {
    English: {
        head1: "Type of Aid",
        head2: "Cost per Item",
        head3: "Number of Items",
        head4: "Total Expenses",
        col1: "Food Kits",
        col2: "Sanitation Kits",
        
    },
    Japanese: {
      head1: "支援種類",
      head2: "1単位の価格",
      head3: "キットの数",
      head4: "合計額",
      col1: "食糧キット",
      col2: "衛生キット",
    }
  };
  language === "Japanese" ? (content = content.Japanese) : (content = content.English);

  return (
    <Table key={i}>
      <Tr class='head'>
        <Td>{content.head1}</Td>
        <Td>{content.head2}</Td>
        <Td>{content.head3}</Td>
        <Td>{content.head4}</Td>
        </Tr>
        <Tr class='row'>
          <Td>{content.col1}</Td>
          <Td><p>USD 25/</p> <p>JPY 2,660/</p> <p>INR 1,800</p></Td>
          <Td>225</Td>
          <Td><p>USD 5,500/</p> <p>JPY 600,000/</p> <p>INR 4,00,000</p></Td>
        </Tr>
        <Tr class='row'>
          <Td>{content.col2}</Td>
          <Td><>USD 2/</> <p>JPY 200/</p> <p>INR 150</p></Td>
          <Td>200</Td>
          <Td><p>USD 500/</p> <p>JPY 50,000/</p> <p>INR 36,000</p></Td>
        </Tr>
    </Table>
  );
}
