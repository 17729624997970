import React from 'react';
import { Table, Tr, Td, Th } from 'react-super-responsive-table';
import './AboutTable.css'

export default function AboutTable(props) {
  let content = {
    English: {
        h1: "Name of the Organization",
        h2: "Key person of the institution",
        h3: "Years in Existence",
        h4: "Postal Address",
        h5: "Email",
        h6: "Website",
        h7: "Contact Number",
        h8: "Society Registration Details",
        h9: "FCRA Registration Details",
        h10: "TAN Number",
        p1: "Mr. Sanjay Singh (Secretary)",
        
    },
    Japanese: {
      h1: "団体名",
      h2: "団体の代表",
      h3: "団体の活動期間",
      h4: "住所",
      h5: "メールアドレス",
      h6: "ホームページ",
      h7: "電話番号",
      h8: "社会登録番号",
      h9: "FCRA番号",
      h10: "TAN番号",
      p1: "サンジェー・シング氏（幹事）",
    }
  };
  props.language === "Japanese" ? (content = content.Japanese) : (content = content.English);
  return (
    <Table>
      <Tr>
        <Th class='type'>{content.h1}</Th>
        <Td bgcolor='#fefefe'>Parmarth Samaj Sevi Sansthan</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h2}</Th>
        <Td bgcolor='#fefefe'>{content.p1}</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h3}</Th>
        <Td bgcolor='#fefefe'>23</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h4}</Th>
        <Td bgcolor='#fefefe'>Mona House, Churkhi Road, Orai, District – Jalaun, Orai (U.P) Pin-285001</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h5}</Th>
        <Td bgcolor='#fefefe'>parmarths@gmail.com</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h6}</Th>
        <Td bgcolor='#fefefe'><a href ="https://www.parmarthindia.com">https://www.parmarthindia.com</a></Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h7}</Th>
        <Td bgcolor='#fefefe'>+91-9415114151</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h8}</Th>
        <Td bgcolor='#fefefe'>Registration No – 624, Dated – 11th Jan 1996</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h9}</Th>
        <Td bgcolor='#fefefe'>FCRA No. 136500010</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h10}</Th>
        <Td bgcolor='#fefefe'>SHLI00744C</Td>
        </Tr>
    </Table>
  );
}
