import styled from 'styled-components';
  
export const Content = styled.div`
   overflow-Y: scroll;
   height: 2500px;
`;
  
export const Button = styled.div`
   position: fixed; 
   width: 100%;
   left: 95%;
   bottom: 35px;
   height: 10px;
   font-size: 2rem;
   z-index: 1;
   cursor: pointer;
   color: #D13E1B;

   @media screen and (max-width: 768px) {
    left: 90%;
   }


`