import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'

export const Nav = styled.nav`
    /* background: ${({ scrollNav }) => (scrollNav ? '#000' : '#000')}; */
    background: #000;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;
    /* margin-top: -80px; */
    
    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }
`;

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    width: 100%;
    z-index: 1;
    padding: 0 24px;
    max-width: 1300px;
`;

export const NavLogo = styled.h1`
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    margin-left: 10px;
    /* font-weight: bold; */
    text-decoration: none;
`;

export const HamburgerIcon = styled.div`
    display: none;
    
    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.5rem;
        cursor: pointer;
        color: #fff;
    }
`;
export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: none;
    /* margin-right: -22px; */

    @media screen and (max-width:768px) {
        display: none;
    }
`;
export const NavItem = styled.li`
    height: 60px;
`;
export const NavLinks = styled(LinkR)`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    &.active {
        border-bottom: 3px solid #D13E1B;
    }
`;

export const NavIcon = styled.img`
    height: 35px;
    width: 35px;
    @media screen and (max-width:768px) {
        height: 30px;
        width: 30px;
    }
`;

export const HomeLogo = styled(LinkR)`
    text-decoration: none;
    cursor: pointer;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

export const NavbarDropdown = styled.div`
    position: relative;
    display: inline-block;

    &:hover {
      display: block;
      >div {
          display: block;
      }
    }

    @media screen and (max-width:768px) {
        display: none;
    }
  `;

export const NavbarDropdownContent = styled.div`
    display: none;
    position: absolute;
    background-color: #000;
    min-width: 100px;
    z-index: 1;

`;

export const StyledLink = styled(LinkR)`
    display: block;
    text-decoration: none;
    color: #fefefe;
    padding: 10px 15px;
    
    &:focus, &:visited, &:link, &:active {
        text-decoration: none;
        color: #fefefe;
    }

    &:hover {
        background-color: #D13E1B;
        text-decoration: none;
        color: #fefefe;
    }
`;

export const LangSwitcher = styled.select`
    padding: 2px;
    background-color: #D13E1B;
    border: transparent;
    color: #fff;
    font-weight: bold;
    text-align: center;
    border-radius: 5%;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-left: 20px;
    margin-bottom: -2px;
`

export const NavSuper= styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 10px;

    @media screen and (max-width:768px) {
        margin-left: -10px;
    }
`