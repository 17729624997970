import React from 'react';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

const Slideshow = (props) => {
  let content = {
    English: {
      images : [{
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621731473/tokyo/ngo4/1_l3dhow.png',
        alt:'Our researcher, Shreya Singh in conversation with Ms. Tanya Khera, co-founder of Samanta Foundation'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621731473/tokyo/ngo4/2_axrx8o.png',
        alt: 'Samanta aims to ensure continuity of education'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621731473/tokyo/ngo4/3_bldayr.png',
        alt: 'Mr. Prashant Anand, co-founder of Samanta with kids'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621731474/tokyo/ngo4/4_a3fbpm.png',
        alt: 'Samanta’s uniquely created learning content'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621731474/tokyo/ngo4/5_lvp119.png',
        alt: 'A glimpse at working with forest communities'
      },
    ]
        
    },
    Japanese: {
      images : [{
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621731473/tokyo/ngo4/1_l3dhow.png',
        alt:'当方の調査員シュリア・シンと、Samantaの共同創設者のタニャ・ケラ氏との対談'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621731473/tokyo/ngo4/2_axrx8o.png',
        alt: 'Samantaは持続的な教育の確立を目指している'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621731473/tokyo/ngo4/3_bldayr.png',
        alt: '子供たちと共に写る共同創設者プラシャント・アナンド氏'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621731474/tokyo/ngo4/4_a3fbpm.png',
        alt: 'Samanta独自につくられた学習教材'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621731474/tokyo/ngo4/5_lvp119.png',
        alt: '森林コミュニティと共に活動する様子'
      },
    ]
    }
  };
  props.language === "Japanese" ? (content = content.Japanese) : (content = content.English);

  return (
    <Carousel images={content.images} style={{ height: 300, width: 400 }} isLoop={true} hasThumbnails={false} hasThumbnailsAtMax={true} isAutoPlaying={true} autoPlayInterval={4000} hasMediaButton={false} hasIndexBoard={false} hasCaptions={'bottom'} hasLeftButton={true} hasRightButton={true} hasSizeButton={'topRight'} widgetsHasShadow={true}/>
  );
};

export default Slideshow;