import React from 'react';
import { Table, Tr, Td } from 'react-super-responsive-table';
import './CovidTable.css'

export default function ItemTable({i, language}) {
  let content = {
    English: {
        t1: "Item Distributed",
        t2: "Quantity",
        h1: "Masks",
        h2: "Medicines",
        h3: "Ration Kits",
        h4: "Sanitizer (100ml)",
        h5: "Handwash",
        h6: "PPE Kit",
        h7: "Gloves",
        h8: "Face Shield",
        
    },
    Japanese: {
      t1: "供給物",
      t2: "数量",
      h1: "マスク",
      h2: "医薬用品",
      h3: "食糧キット",
      h4: "消毒薬　(100ml)",
      h5: "ハンドソープ",
      h6: "防護服",
      h7: "手袋",
      h8: "フェイスシールド",
        
    }
  };
  language === "Japanese" ? (content = content.Japanese) : (content = content.English);
  return (
    <Table key={i}>
      <Tr class='head'>
        <Td>{content.t1}</Td>
        <Td>{content.t2}</Td>
        </Tr>
        <Tr class='row'>
          <Td>{content.h1}</Td>
          <Td>20000</Td>
        </Tr>
        <Tr class='row'>
          <Td>{content.h2}</Td>
          <Td>5000</Td>
        </Tr>
        <Tr class='row'>
          <Td>{content.h3}</Td>
          <Td>8000</Td>
        </Tr>
        <Tr class='row'>
          <Td>{content.h4}</Td>
          <Td>10000</Td>
        </Tr>
        <Tr class='row'>
          <Td>{content.h5}</Td>
          <Td>3500</Td>
        </Tr>
        <Tr class='row'>
          <Td>{content.h6}</Td>
          <Td>500</Td>
        </Tr>
        <Tr class='row'>
          <Td>{content.h7}</Td>
          <Td>5000</Td>
        </Tr>
        <Tr class='row'>
          <Td>{content.h8}</Td>
          <Td>2000</Td>
        </Tr>
    </Table>
  );
}
