import styled from 'styled-components'

export const HomeContainer = styled.div`
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0px;
    height: 540px;
    position: relative;
    z-index: 1;

    @media screen and (max-width:480px) {
        height: 480px;
    }
`;

export const HomeBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    overflow: hidden;
`;

export const PhotoBg = styled.img`
    width: 100%;
    height: 100%;
    --o-object-fit: cover;
    object-fit: cover;
    filter: brightness(40%);
`;

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    --o-object-fit: cover;
    object-fit: cover;
    filter: brightness(40%);
`;

export const HomeContent = styled.div`
 z-index: 3;
 max-width: 1200px;
 position: absolute;
 padding: 8px 24px;
 display: flex;
 flex-direction: column;
 align-items: center;

 @media screen and (max-width:768px) {
    max-width: 320px;
    }
`;

export const HomeH1 = styled.h1`
    color: #fff;
    font-size: 48px;
    text-align: center;

    @media screen and (max-width:768px) {
        font-size: 40px;
    }

    @media screen and (max-width:480px) {
        font-size: 32px;
    }
`;

export const HomeP = styled.p`
    margin-top: 24px;
    color: #fff;
    font-size: 24px;
    text-align: center;
    max-width: 600px;

    @media screen and (max-width:768px) {
        font-size: 24px;
    }

    @media screen and (max-width:480px) {
        font-size: 18px;
    }
`;

export const HomeBtnWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 30px;

    @media screen and (max-width:768px) {
        margin-right: 10px;
    }

`;

export const TrioContainer = styled.div`
    align-items: center;
    justify-content: space-evenly;
    margin: 0 350px 0 300px;
    display: flex;
    flex-direction: row;
    height: 280px;

    @media screen and (max-width:768px) {
        flex-direction: column;
        height: 620px;
        margin: 0 0 0 0;
        justify-content: center;
        padding: 40px;
        justify-content: space-between;

    }
`
export const HomePP = styled.p`
    margin-top: 24px;
    color: #fff;
    font-size: 20px;
    text-align: center;
    max-width: 600px;

    @media screen and (max-width:768px) {
        font-size: 12px;
    }
`;