import HomeSection from '../components/Home'
import Mission from '../components/Mission'
import Team from '../components/Team'
import Contact from '../components/Contact'
import NGOs from '../components/NGOs'
// import Countdown from '../components/Countdown'
import './style.css'
import ProgressBar from '../components/Progress'
import { TrioContainer } from '../components/Home/HomeElements'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import { useState } from 'react'
import React from 'react'
import Footer from '../components/Footer'


const asiaLogo = "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1621648794/tokyo/asia_blrejg.png";

const Home = () => {
    let languageStoredInLocalStorage = localStorage.getItem("language");
  let [language, setLanguage] = useState(
    languageStoredInLocalStorage ? languageStoredInLocalStorage : "English"
  );
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }
    return (
        <>  
            <Sidebar isOpen={isOpen} toggle={toggle} language={language} />
            <Navbar toggle={toggle} language={language}
        handleSetLanguage={language => {
          setLanguage(language);
          storeLanguageInLocalStorage(language);
        }}/>
            <HomeSection language={language} />
            <TrioContainer>
                <ProgressBar language={language} />
                <p className= "thanks">{`We are thankful to each and every one of our supporters for their contribution.\n\n Now, we are working on project implementation and will share regular updates on our social media handles.\n\n In September, we will release a project report at an online event.\n\n Thank you!`}</p>
                {/* <Countdown language={language} timeTillDate="06 28 2021, 18:30 pm" timeFormat="MM DD YYYY, hh:mm a" /> */}
                <img src={asiaLogo} alt='asia-initiatives' width='200px' height="auto"/>
            </TrioContainer>
            <NGOs language={language}/>
            <Mission language={language}/>
            <Team language={language}/>
            <Contact language={language} />
            <Footer language={language}/>

        </>
    )
    function storeLanguageInLocalStorage(language) {
      localStorage.setItem("language", language);
    }
}


export default Home
