import React from 'react';
import { Table, Tr, Td } from 'react-super-responsive-table';
import './CovidTable.css'

export default function CovidTable({i, language}) {
  let content = {
    English: {
        head1: "Type of Aid",
        head2: "Cost per Kit",
        head3: "Planned No. of Families",
        head4: "Total Cost",
        col1: "Ration Kits",
        col2: "Sanitation Kits",
        
    },
    Japanese: {
      head1: "支援種類",
      head2: "1単位の価格",
      head3: "支援予定の家庭数",
      head4: "合計コスト",
      col1: "食糧キット",
      col2: "衛生キット",
    }
  };
  language === "Japanese" ? (content = content.Japanese) : (content = content.English);
  return (
    <Table key={i}>
      <Tr class='head'>
        <Td>{content.head1}</Td>
        <Td>{content.head2}</Td>
        <Td>{content.head3}</Td>
        <Td>{content.head4}</Td>
        </Tr>
        <Tr class='row'>
          <Td>{content.col1}</Td>
          <Td><p>USD 9.85/</p> <p>JPY 1,100/</p> <p>INR 715</p></Td>
          <Td>750</Td>
          <Td><p>USD 7,000/</p> <p>JPY 780,000/</p> <p>INR 5,00,000</p></Td>
        </Tr>
        <Tr class='row'>
          <Td>{content.col2}</Td>
          <Td><p>USD 0.96/</p> <p>JPY 104/</p> <p>INR 70</p></Td>
          <Td>750</Td>
          <Td><p>USD 750/</p> <p>JPY 90,000/</p> <p>INR 55,000</p></Td>
        </Tr>
    </Table>
  );
}
