import styled from 'styled-components'


// export const StyledLink = styled(Link)`
//     text-decoration-color: #000;

//     &:focus, &:hover, &:visited, &:link, &:active {
//         text-decoration-color: #000;
//     }
// `;

export const Red = styled.span`
    color: #ff0000;
    font-weight: 400;

`
export const NGOPageContainer1 = styled.div`
    background: #222;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 0 0px; */
    height: 160px;
    position: relative;
    z-index: 1;

    @media screen and (max-width:768px){
        height: 200px;
        
    }
`
export const NGOHeaderWrap = styled.div`
    display: flex;
    flex-wrap: row;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    /* width: 1200px; */
`
export const NGOIcon = styled.img`
    height: 120px;
    width: 120px;
    margin-right: 20px;
    margin-left: 40px;

    @media screen and (max-width:768px){
        width: 80px;
        height: 80px;
        margin-right: 10px;
        margin-left: 60px;
        /* margin-left: 100px; */
        /* margin-right: 100px; */
        /* padding-right: -50px; */
    }
`
export const NGOName = styled.h1`
    text-transform: uppercase;
    font-size: 2rem;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 10px;

    @media screen and (max-width: 480px) {
        font-size: 1.25rem;
        width: 300px;
    }
`
export const NGOInfo = styled.p`
    text-align: left;
    font-size: 0.9rem;
    margin-top: 2.5px;
    color: #eee;

    @media screen and (max-width: 480px) {
        font-size: 0.9rem;
        width: 250px;
        margin-top:5px;
    }

`
export const NGOLeft = styled.div`
    /* padding: 0 120px; */
    /* margin-right: -120px; */

`

export const NGORight = styled.div`
    margin-bottom: 15px;
    /* padding: 0 100px; */
    /* margin-left: -50px; */

    @media screen and (max-width: 480px) {
        /* padding: 0 65px; */
    }

`

export const NGOCovidInfoH1 = styled.h1`
    margin-bottom: 1.25rem;
    color: #D13E1B;
    font-size: 1.8rem;
    text-align: left;

    @media screen and (max-width: 480px) {
        font-size: 1.6rem;
        text-align: center;
        margin-bottom: 0.5rem;
        amrgin-top: 0.25rem

    }

`;

export const NGOCovidInfop = styled.p`
    margin-bottom: 1rem;
    font-size: 1rem;
    /* line-height: 24px; */
    color: #0c0c0c;
    text-align: left;

    @media screen and (max-width: 480px) {
        font-size: 0.8rem;
        text-align: justify;
        padding: 0 20px;
        margin-bottom: 0.5rem;
    }
`;


export const NGOCardWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 20px;
    /* padding: 0 40px; */
    /* margin-top: 10px; */
    justify-content: center;

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
        max-width: 400px;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }
`
export const NGOCovidCard = styled.img`
    width: 180px;

    &:hover {
        transform: scale(1.02);
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    @media screen and (max-width: 768px) {
        width: 160px;
    }
`;


export const NGOMap = styled.img`
    width: 450px;
    padding-left: 10px;
    height: auto;

    @media screen and (max-width: 768px) {
        /* margin: 0 auto  0; */
        width: 350px;
        padding-left: 0;
    }  
`

export const NGOAwards = styled.img`
    width: 40%;
    height: auto;
    margin-left: -50px;
    margin-top: 50px;

    @media screen and (max-width: 768px) {
        margin: 0 auto;
        width: 90%;
        margin-bottom: -100px;
        align-items: center;
        /* margin-left: 0; */
    }  
`

export const NGOQuote = styled.div`
    width: 320px;

    @media screen and (max-width: 768px) {
        margin-top: 25px;

    }  
`

export const NGOContentColumn1 = styled.div`
    display: flex;
    width: 500px;
    flex-direction: column;
    padding: 0 50px;
    align-items: left;
    justify-content: center;

    @media screen and (max-width: 768px) {
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        margin-right: 10px;
    }

`
export const NGOContentColumn2 = styled.div`
    display: flex;
    width: 500px;
    flex-direction: column;
    padding: 0 50px;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        margin-right: 10px;
    }

`

export const NGOPageContainer2 = styled.div`
    height: 480px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
        height: 900px;
    }  

    @media screen and (max-width: 768px) {
        flex-direction: column;
        height: 800px;
    }    
`

export const NGOPageContainer3 = styled.div`
    height: 320px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #eee;

    @media screen and (max-width: 1000px) {
        height: 500px;
        flex-direction: column;
    }  

    @media screen and (max-width: 768px) {
        height: 560px;
        flex-direction: column;
    }    
`

export const NGOPageContainer4 = styled.div`
    height: 600px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1000px) {
        height: 1000px;
        flex-direction: column;
    }  

    @media screen and (max-width: 768px) {
        height: 1100px;
        flex-direction: column;
    }    
`

export const NGOPageContainer5 = styled.div`
    height: 400px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #eee;

    @media screen and (max-width: 1000px) {
        height: 500px;
        flex-direction: column;
    }  

    @media screen and (max-width: 768px) {
        height: 500px;
        flex-direction: column;
    }    
`
export const NGOPageContainer6 = styled.div`
    height: 260px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1000px) {
        height: 400px;
        flex-direction: column;
    }  

    @media screen and (max-width: 768px) {
        height: 340px;
        flex-direction: column;
    }    
`
export const NGOPageContainer7 = styled.div`
    height: 60px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 70px;

    @media screen and (max-width: 1000px) {
        height: 1000px;
        flex-direction: column;
    }  

    @media screen and (max-width: 768px) {
        height: 0px;
        flex-direction: column;
        margin-bottom: 100px;
    }    
`