import React, { useState } from 'react'
import Navbar from '../components/Navbar3'
import Sidebar from '../components/Sidebar3'
import TeamPage from '../components/TeamPage'
import Footer from '../components/Footer'



const Team = () => {
    let languageStoredInLocalStorage = localStorage.getItem("language");
  let [language, setLanguage] = useState(
    languageStoredInLocalStorage ? languageStoredInLocalStorage : "English"
  );
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }
    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} language={language}/>
            <Navbar toggle={toggle} language={language}
        handleSetLanguage={language => {
          setLanguage(language);
          storeLanguageInLocalStorage(language);
        }}/>
            <TeamPage language={language}/>
            <Footer language={language}/>
        </>
    )
    function storeLanguageInLocalStorage(language) {
        localStorage.setItem("language", language);
      }
}

export default Team