import React from 'react'
import { FooterContainer, FooterWrapper, SocialMedia, SocialLogo, WebsiteRights, SocialIcons, SocialIconLink, SocialMediaWrap } from './FooterElements'
import { FaFacebook } from 'react-icons/fa'
import { RiInstagramFill } from 'react-icons/ri'
import { IoMail } from 'react-icons/io5'
import { animateScroll as scroll } from 'react-scroll'

const Footer = (props) => {
    const toggleHome = () => {
        scroll.scrollToTop();
    }
    let content = {
        English: {
            title: "Tokyo for India",
            middle: '2021 All rights reserved.',

        },
        Japanese: {
            title: "東京からインドへ",
            middle: '2021 すべての権利を保有。',

        }
      };
      props.language === "Japanese" ? (content = content.Japanese) : (content = content.English);
    return (
        <FooterContainer>
            <FooterWrapper>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo onClick={toggleHome}>{content.title}</SocialLogo>
                        <WebsiteRights>{content.title} &copy; {content.middle}</WebsiteRights>
                        <SocialIcons>
                        <SocialIconLink href="https://www.instagram.com/tokyoforindia/" target = "_blank" aria-label="Instagram"><RiInstagramFill/></SocialIconLink>
                        <SocialIconLink href="https://www.facebook.com/Tokyo-for-India-107580711538965" target = "_blank" aria-label="Facebook"><FaFacebook/></SocialIconLink>
                        <SocialIconLink href="mailto:tokyoforindia@gmail.com" target = "_blank" aria-label="Mail"><IoMail/></SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrapper>
        </FooterContainer>
    )
}

export default Footer
