import styled from 'styled-components'

export const PhotoContainer = styled.div`
    background: #222;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0px;
    height: 200px;
    position: relative;
    z-index: 1;
    @media screen and (max-width: 768px) {
        height: 100px;
}
`;

export const PhotoContent = styled.div`
 z-index: 3;
 max-width: 1200px;
 position: absolute;
 padding: 8px 24px;
 display: flex;
 flex-direction: column;
 align-items: center;
`;

export const AboutContainer = styled.div`
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 500px;
    z-index: 1;
    position: relative;
    @media screen and (max-width: 768px) {
        height: 800px;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 0;

}
`;
export const Subheading = styled.p`
    max-width: 500px;
    margin-bottom: 25px;
    font-size: 18px;
    color: #000;
    text-align: justify;

    @media screen and (max-width:480px) {
        font-size: 16px;
        padding: 0 40px 0 40px;
    }
`;


export const Heading = styled.h1`
    text-align: center;
    color: #fff;
    font-size: 4rem;
    text-transform: uppercase;

    @media screen and (max-width:480px) {
        font-size: 2rem;
    }
`

export const PhotoBg = styled.img`
    width: 100%;
    height: 100%;
    --o-object-fit: cover;
    object-fit: contain;
    filter: brightness(30%);
`

export const Container = styled.div`
    height: 2700px;
    background: #eee;

    @media screen and (max-width: 768px) {
    height: 9000px;

}
`

export const Section = styled.div`
    align-items: center;
    justify-content: center;
    padding: 25px;

`

export const Title = styled.h1`
    text-align: center;
    color: #D13E1B;
    font-weight: bold;
    font-size: 2rem;
`

export const CardWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* grid-template-columns: 1fr 1fr 1fr 1fr;
    max-width: 1200px;
    margin: 0 auto 25px auto;
    grid-gap: 10px; */

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }


`
export const Card = styled.div`
    height: 250px;
    width: 200px;
    background: #fefefe;
    box-shadow: 0 10px 30px -5px rgba(color(dark), 0.2);
	text-align: center;
    border-radius: 5%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 25px;
    @media screen and (max-width: 768px) {
        margin: 0;
        margin-bottom: 25px;
        margin-top: 25px;
    }

`
export const Name = styled.h1`
    color: #0c0c0c;
    font-size: 1rem;
    font-weight: bold;
    padding-top: 10px;
    padding: 0 15px 0 15px;
`
export const College = styled.p`
    color: #555;
    font-size: 0.9rem;
    padding: 0 20px 0 20px;

`
export const Pic = styled.img`
    font-size: 1rem;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    display: block;
`

export const FlagWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

export const Column1 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 650px;
    padding-left: 10px;
    padding-right: 10px;
    @media screen and (max-width: 768px) {
        padding-left: 0;
        padding-right: 0;
    }
`

export const Column2 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 650px;
    padding-left: 10px;
    padding-right: 10px;
    @media screen and (max-width: 768px) {
        padding-left: 0;
        padding-right: 0;
    }
`