import React from 'react';
import { Table, Tr, Td, Th } from 'react-super-responsive-table';
import './AboutTable.css'

export default function AboutTable(props) {
  let content = {
    English: {
        h1: "Name of the Organization",
        h2: "Key person of the institution",
        h3: "Years in Existence",
        h4: "Postal Address",
        h5: "Email",
        h6: "Website",
        h7: "Contact Number",
        h8: "CIN",
        p1: "Edliv Samanta Foundation",
        p2: "Mr. Prashant Anand & Ms. Tanya Khera (co-founders)"
        
    },
    Japanese: {
      h1: "団体名",
      h2: "団体の代表",
      h3: "団体の活動期間",
      h4: "住所",
      h5: "メールアドレス",
      h6: "ホームページ",
      h7: "電話番号",
      h8: "CIN",
      p1: "Edliv Samanta 基金",
      p2: "プラシャント・アナンド氏とタニャ・ケラ氏（共同創設者のお二人）",
    }
  };
  props.language === "Japanese" ? (content = content.Japanese) : (content = content.English);
  return (
    <Table>
      <Tr>
        <Th class='type'>{content.h1}</Th>
        <Td bgcolor='#fefefe'>{content.p1}</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h2}</Th>
        <Td bgcolor='#fefefe'>{content.p2}</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h3}</Th>
        <Td bgcolor='#fefefe'>3</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h4}</Th>
        <Td bgcolor='#fefefe'>Vatika Hills Enclave, Kidduvala, Raipur, DehraDun,Uttarakhand - 248008</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h5}</Th>
        <Td bgcolor='#fefefe'>samantaconnect@gmail.com</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h6}</Th>
        <Td bgcolor='#fefefe'><a href ="https://www.samanta.org.in ">https://www.samanta.org.in</a></Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h7}</Th>
        <Td bgcolor='#fefefe'>+91-9910654474, +91-9837883575</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h8}</Th>
        <Td bgcolor='#fefefe'>U85300UR2019NPL009992</Td>
        </Tr>
    </Table>
  );
}
