import React from 'react';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

const Slideshow = (props) => {
  let content = {
   
    English: {
      images : [{
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621736537/tokyo/ngo5/1_m6xrn2.png',
        alt:'Our researcher, Shreya Singh in conversation with Mr. Sanjay Singh (Secretary) and Mrs. Shivani Singh of Parmarth'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621736536/tokyo/ngo5/2_l0og5c.jpg',
        alt: 'Parmarth increasing awareness of COVID-19'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621736536/tokyo/ngo5/3_bhr7t4.jpg',
        alt: 'Communicating with the local population through slogans'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621736536/tokyo/ngo5/4_whbeul.jpg',
        alt: 'Learning all about social distancing-with Parmarth'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621736535/tokyo/ngo5/5_a4up59.jpg',
        alt: 'Distribution of masks for COVID-19'
      },
    ]
  },
    Japanese: {
      images : [{
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621736537/tokyo/ngo5/1_m6xrn2.png',
        alt:'当方の調査員Shreya Sing.と、ParmarthのMr. Sanjay Singh (秘書) と Mrs. Shivani Singh'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621736536/tokyo/ngo5/2_l0og5c.jpg',
        alt: 'コロナウイルスに関する意識向上のための活動を行っているParmarth'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621736536/tokyo/ngo5/3_bhr7t4.jpg',
        alt: 'スローガンを通して、地元の人へ伝える'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621736536/tokyo/ngo5/4_whbeul.jpg',
        alt: 'Parmarthとのソーシャルディスタンスについて学ぶ'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621736535/tokyo/ngo5/5_a4up59.jpg',
        alt: 'マスク配布'
      },
    ]
         
    }
  };
  props.language === "Japanese" ? (content = content.Japanese) : (content = content.English); 

  return (
    <Carousel images={content.images} style={{ height: 300, width: 400 }} isLoop={true} hasThumbnails={false} hasThumbnailsAtMax={true} isAutoPlaying={true} autoPlayInterval={4000} hasMediaButton={false} hasIndexBoard={false} hasCaptions={'bottom'} hasLeftButton={true} hasRightButton={true} hasSizeButton={'topRight'} widgetsHasShadow={true}/>
  );
};

export default Slideshow;