import React from 'react';
import { Table, Tr, Td, Th } from 'react-super-responsive-table';
import './AboutTable.css'

export default function Aboutable(props) {
  let content = {
    English: {
        h1: "Name of the Organization",
        h2: "Key person of the institution",
        h3: "Years in Existence",
        h4: "Postal Address",
        h5: "Email",
        h6: "Website",
        h7: "Contact Number",
        h8: "Society Registration Details",
        h9: "FCRA Registration Details",
        h10: "TAN Number",
        p: "Mr. Shankar Singh Rawat (founder & secretary)"
        
    },
    Japanese: {
      h1: "団体名",
      h2: "団体の代表",
      h3: "団体の活動期間",
      h4: "住所",
      h5: "メールアドレス",
      h6: "ホームページ",
      h7: "電話番号",
      h8: "社会登録番号",
      h9: "FCRA番号",
      h10: "TAN番号",
      p: "シャンカー・シング・ラワット氏（設立者・幹事）"
    }
  };
  props.language === "Japanese" ? (content = content.Japanese) : (content = content.English);
  return (
    <Table>
      <Tr>
        <Th class='type'>{content.h1}</Th>
        <Td bgcolor='#fefefe'>Grameen Mahila Vikas Sansthan</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h2}</Th>
        <Td bgcolor='#fefefe'>{content.p}</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h3}</Th>
        <Td bgcolor='#fefefe'>23</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h4}</Th>
        <Td bgcolor='#fefefe'>Near Pataka Factory, Raja Reddy, Sumer Nagar, Madanganj Kishangarh, Ajmer (Rajasthan)</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h5}</Th>
        <Td bgcolor='#fefefe'>bubanigmvs@gmail.com, gmvsajmer@gmail.com </Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h6}</Th>
        <Td bgcolor='#fefefe'><a href ="http://www.gmvs.org.in">http://www.gmvs.org.in</a></Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h7}</Th>
        <Td bgcolor='#fefefe'>+91-9672979032</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h8}</Th>
        <Td bgcolor='#fefefe'>52/Ajmer/1998-99 </Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h9}</Th>
        <Td bgcolor='#fefefe'>FCRA No.125410040</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h10}</Th>
        <Td bgcolor='#fefefe'>JDHG10077E</Td>
        </Tr>
    </Table>
  );
}
