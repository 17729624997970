import React from 'react'
import { VideoBg, ErrorContainer, ErrorContent, ErrorH1, ErrorP} from './ErrorElements';


const Video = "https://res.cloudinary.com/djrobin17/video/upload/q_auto:low,f_auto/v1621740275/tokyo/children_rjpdzt.mp4";

const NotFound = (props) => {
    let content = {
        English: {
            h1: "ERROR 404",
            h2: "Page Not Found",

        },
        Japanese: {
            h1: "エラー404",
            h2: "ページが見つかりません",
        }
      };
      props.language === "Japanese" ? (content = content.Japanese) : (content = content.English);
    return (
        <>
            <ErrorContainer id="home">
            <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
            <ErrorContent>
            <ErrorH1>{content.h1}</ErrorH1>
            <ErrorP>{content.h2}</ErrorP>
            </ErrorContent>
            </ErrorContainer>
        </>
    )
}

export default NotFound
