import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import React from 'react'
import Home from './pages';
import ScrollToTop from './components/ScrollToTop';
import HttpsRedirect from 'react-https-redirect';
import NGO1 from './pages/ngo1';
import NGO2 from './pages/ngo2';
import NGO3 from './pages/ngo3';
import NGO4 from './pages/ngo4';
import NGO5 from './pages/ngo5';
import NotFound from './pages/error';
import Team from './pages/team';
import Mission from './pages/mission';
import ScrollButton from './components/ScrollTop';
import ContactUs from './pages/contact';


function App(props) {

  return (
    <HttpsRedirect>
    <Router>
    <ScrollToTop />
      <Switch>
        <Route path='/' component={Home} exact />
        <Route path='/gmvs' component={NGO1} exact />
        <Route path='/hrds' component={NGO2} exact />
        <Route path='/ingon' component={NGO3} exact />
        <Route path='/parmarth' component={NGO4} exact />
        <Route path='/samanta' component={NGO5} exact />
        <Route path='/team' component={Team} exact />
        <Route path='/mission' component={Mission} exact />
        <Route path='/contact' component={ContactUs} exact />
        <Route path="" component={NotFound} />  
      </Switch>
      <ScrollButton />
    </Router>
    </HttpsRedirect>
  )
}

export default App;
