import React from 'react'
import { Card, CardWrap, Container, Section, Title, Pic, Name, College, PhotoContent, PhotoContainer, Heading, FlagWrap, AboutContainer, Subheading, Column1, Column2 } from './TeamPageElements'
import Flag from 'react-flagkit';
import YouTube from 'react-youtube';
import { StyledLink } from '../Team/TeamElements';
import { V1Wrap, V2Wrap } from '../MissionPage/MissionPageElements';
import JsxParser from 'react-jsx-parser'

const blank = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622338845/tokyo/team/fd14a484f8e558209f0c2a94bc36b855_vzmhv2.png'
const devang = "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622179347/tokyo/devang_jain_wwnyci.jpg"
const amishi = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622216349/tokyo/team/Amishi_f8j4v0.png'
const shreya = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622216439/tokyo/team/64906512-C061-4B2E-848E-C02D10D336CB_-_Shreya_Singh_bsmlq6.png'
const chris = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622216628/tokyo/team/Screen_Shot_2021-05-13_at_21.48.43_-_Chris_Clayton_bq3yoc.png'
const mahi = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622216774/tokyo/team/T021J9JUQQN-U022H1FQMMW-60370e2042a0-512_bkx9ry.png'
const kihara = "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622321651/tokyo/team/Profile_Ai_Kihara-Hunt_rimq69.jpg"
const asia = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1621558990/tokyo/asia-initiatives_e3ymtm.png'
const geeta = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622338402/tokyo/team/download_7_ieww9l.jpg'
const mahika = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622438353/tokyo/team/103689B7-0952-4C4A-8EAA-A126B0F22750_-_Voyager_qgv5yd.jpg'
const jenna = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622438354/tokyo/team/485C7E91-73A7-49FB-A510-FD9D5037E529_-_Jenna_Stallard_hu3nip.jpg'
const mukesh = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622438778/tokyo/team/IMG_20210528_152154_-_Mukesh_Jangid_xpm2ju.jpg'
const rhutvi = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622438773/tokyo/team/IMG_20210315_232623_841_-_Rhuti_Ahir_rbbwit.jpg'
const kam = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622439946/tokyo/team/IMG_20210528_112705_-_Kam_Longkumer_xyqo4q.jpg'
const swetha = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622439947/tokyo/team/IMG-20210528-WA0011__01_-_Swetha_Soundararajan_vyxshw.jpg'
const karan = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622439947/tokyo/team/IMG_20210329_202138_-_KARANBIR_SINGH_gxbzzu.jpg'
const ishita = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622441001/tokyo/team/2021-01-01-113221324_-_Ishita_Agarwal_ny2pyh.jpg'
const dhruv = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622441614/tokyo/team/1617026479734_-_Dhruv_Iyer_eaa7p0.jpg'
const murao = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622441691/tokyo/team/0669D9C1-0445-406C-A110-6892AE9DD088_-_Kazutaka_Murao_fidamd.jpg'
const shota = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622441784/tokyo/team/58C19E5C-F6E5-40AA-AC17-85A05498819B_-_Shota_Arai_kqzsw0.jpg'
const yuko = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622441787/tokyo/team/994CCEDF-A95B-49AB-93E4-ED50C5E18969_-_Yuko_Fujinaga_mgc2xu.jpg'
const yusuke = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622441821/tokyo/team/Y_Kumaki_ProfilePic_TfI_-_Yusuke_Kumaki_lgld4z.jpg'
const saya = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622441782/tokyo/team/A7F66246-C911-4E66-8BBA-86BBD21349EE_-_Saya_Miyake_s9erqo.jpg'
const gil = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622441689/tokyo/team/157E93BE-ABE3-4713-BF44-1F2FDE215014_-_Gil_Padit_tsejem.jpg'
const naoka = 'https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622477441/tokyo/team/B838F40A-1D3D-412F-98C3-DA6B74F64164_-_Naoko_Motohata_taqkqg.jpg'


const TeamPage = (props) => {
    let content = {
        English: {
            title: "MEET THE TEAM",
            para1: "Tokyo for India is a global student-led initiative supported by a team of more than 30 university students from Japan and India, extending to the USA, the UK, Spain, Australia, Singapore and beyond. We have come together to launch an emergency fundraiser to support and enable critical COVID-relief services for marginalised communities in different parts of rural India.",
            para2: `We are grateful to be holding this fundraiser in partnership with <StyledLink href ='https://asiainitiatives.org'>Asia Initiatives</StyledLink> (AI), a registered NPO in Japan and the USA. AI was <StyledLink href ='https://asiainitiatives.org/our-story/'>founded in Tokyo</StyledLink> and has <StyledLink href ='https://asiainitiatives.org/about/partners/'>partnered</StyledLink> with local non-profit organizations across 20 locations in India. They are doing exemplary work in providing critical relief services and livelihood opportunities since COVID-19 started.`,
            s: 'Social Media Team',
            g: 'Graphics Team',
            t: 'Translations Team',
            r: 'Research & Website Team',
        },
        Japanese: {
            title: "チームのご紹介",
            para1: "Tokyo for India is a global student-led initiative supported by a team of more than 30 university students from Japan and India, extending to the USA, the UK, Spain, Australia, Singapore and beyond. We have come together to launch an emergency fundraiser to support and enable critical COVID-relief services for marginalised communities in different parts of rural India.",
            para2: `We are grateful to be holding this fundraiser in partnership with <StyledLink href ='https://asiainitiatives.org'>Asia Initiatives</StyledLink> (AI), a registered NPO in Japan and the USA. AI was <StyledLink href ='https://asiainitiatives.org/our-story/'>founded in Tokyo</StyledLink> and has <StyledLink href ='https://asiainitiatives.org/about/partners/'>partnered</StyledLink> with local non-profit organizations across 20 locations in India. They are doing exemplary work in providing critical relief services and livelihood opportunities since COVID-19 started.`,
            s: 'SNSチーム',
            g: 'グラフィックチーム',
            t: '翻訳チーム',
            r: '研究とウェブサイトチーム',
        }
      };
      props.language === "Japanese" ? (content = content.Japanese) : (content = content.English);
    const opts1 = {
        height: '320',
        width: '500',
    }
    const opts2 = {
        height: '200',
        width: '320',
    }
    return (
        <>
        <PhotoContainer>
        <PhotoContent>
        <Heading>{content.title}</Heading>
        </PhotoContent>
        </PhotoContainer>
        <AboutContainer>
        <Column1>
            <V1Wrap>
            <YouTube videoId="IOWtEBqbhog" opts ={opts1} />
            </V1Wrap>
            <V2Wrap>
            <YouTube videoId="IOWtEBqbhog" opts ={opts2} />
            </V2Wrap>
        </Column1>
        <Column2>
        <Subheading>{content.para1}</Subheading>
        <Subheading><JsxParser jsx={content.para2} components={{ StyledLink }}/></Subheading>
        </Column2>
        </AboutContainer>
        <Container>
            <Section>
                    <CardWrap>
                        <Card>
                            <Pic src = {kihara} />
                            <Name>Prof. Ai Kihara-Hunt</Name>
                            <College>Advisor, University of Tokyo</College>
                            <Flag country = 'JP'/>
                        </Card>
                        <Card>
                            <Pic src = {geeta} />
                            <Name>Dr. Geeta Mehta</Name>
                            <College>Advisor, AI, Columbia University</College>
                            <FlagWrap>
                            <Flag country = 'IN'/>
                            <Flag country = 'JP'/>
                            <Flag country = 'US'/>
                            </FlagWrap>
                        </Card>
                        <Card>
                            <Pic src = {asia} />
                            <Name>Asia Initiatives</Name>
                            <College>Official Partner</College>
                            <FlagWrap>
                            <Flag country = 'JP'/>
                            <Flag country = 'US'/>
                            </FlagWrap>
                        </Card>
                        <Card>
                            <Pic src = {amishi} />
                            <Name>Amishi Agrawal</Name>
                            <College>University of Tokyo</College>
                            <FlagWrap>
                            <Flag country = 'IN'/>
                            <Flag country = 'JP'/>
                            </FlagWrap>
                        </Card>
                    </CardWrap>
                    <CardWrap>
                        <Card>
                            <Pic src = {chris} />
                            <Name>Chris Clayton</Name>
                            <College>University of Tokyo</College>
                            <FlagWrap>
                            <Flag country = 'JP'/>
                            <Flag country = 'AU'/>
                            </FlagWrap>
                        </Card>
                        <Card>
                            <Pic src = {shreya} />
                            <Name>Shreya Singh</Name>
                            <College>Jaipur National University </College>
                            <Flag country = 'IN'/>
                        </Card>
                        <Card>
                            <Pic src = {mahi} />
                            <Name>Mahi Patki</Name>
                            <College>University of Tokyo</College>
                            <FlagWrap>
                            <Flag country = 'IN'/>
                            <Flag country = 'JP'/>
                            </FlagWrap>
                        </Card>
                        <Card>
                            <Pic src = {devang} />
                            <Name>Devang Jain</Name>
                            <College>Indian Institute of Technology, Goa</College>
                            <Flag country = 'IN'/>
                        </Card>
                    </CardWrap>
            </Section>
            {/* <Section>
                <Title>Networking Team</Title>
                    <CardWrap>
                        <Card>
                            <Pic src = {blank} />
                            <Name>Name</Name>
                            <College>College</College>
                            <Flag country = 'JP'/>
                        </Card>
                        <Card>
                            <Pic src = {blank} />
                            <Name>Name</Name>
                            <College>College</College>
                            <Flag country = 'IN'/>
                        </Card>
                        <Card>
                            <Pic src = {blank} />
                            <Name>Name</Name>
                            <College>College</College>
                            <Flag country = 'JP'/>
                        </Card>
                        <Card>
                            <Pic src = {blank} />
                            <Name>Name</Name>
                            <College>College</College>
                            <Flag country = 'IN'/>
                        </Card>
                    </CardWrap>
            </Section> */}
            <Section>
                <Title>{content.s}</Title>
                <CardWrap>
                        <Card>
                            <Pic src = {blank} />
                            <Name>Rishab Prasad</Name>
                            <College>Christ University, Bangalore</College>
                            <Flag country = 'JP'/>
                        </Card>
                        <Card>
                            <Pic src = {mahika} />
                            <Name>Mahika Singh</Name>
                            <College>OP Jindal Global University</College>
                            <Flag country = 'IN'/>
                        </Card>
                        <Card>
                            <Pic src = {blank} />
                            <Name>Akari Takahashi</Name>
                            <College>University of Tokyo</College>
                            <Flag country = 'JP'/>
                        </Card>
                        <Card>
                            <Pic src = {jenna} />
                            <Name>Jenna Stallard</Name>
                            <College>University of Tokyo</College>
                            <FlagWrap>
                            <Flag country = 'JP'/>
                            <Flag country = 'NZ'/>
                            </FlagWrap>
                        </Card>
                    </CardWrap>
            </Section>
            <Section>
                <Title>{content.g}</Title>
                <CardWrap>
                        <Card>
                            <Pic src = {mukesh} />
                            <Name>Mukesh Jangid</Name>
                            <College>MNIT Jaipur</College>
                            <Flag country = 'IN'/>
                        </Card>
                        <Card>
                            <Pic src = {blank} />
                            <Name>Shubhi Agarawal</Name>
                            <College>Srishti Institute of Art and Design, Bangalore</College>
                            <Flag country = 'IN'/>
                        </Card>
                        <Card>
                            <Pic src = {blank} />
                            <Name>Abhishek Soni</Name>
                            <College>MNIT Jaipur</College>
                            <Flag country = 'IN'/>
                        </Card>
                        <Card>
                            <Pic src = {rhutvi} />
                            <Name>Rhutvi Ahir</Name>
                            <College>MNIT Jaipur</College>
                            <Flag country = 'IN'/>
                        </Card>
                    </CardWrap>
            </Section>
            <Section>
                <Title>{content.t}</Title>
                <CardWrap>
                        <Card>
                            <Pic src = {dhruv} />
                            <Name>Dhruv Iyer</Name>
                            <College>University of Tokyo</College>
                            <Flag country = 'JP'/>
                        </Card>
                        <Card>
                            <Pic src = {murao} />
                            <Name>Kazutaka Murao</Name>
                            <College>University of Tokyo</College>
                            <Flag country = 'JP'/>
                        </Card>
                        <Card>
                            <Pic src = {shota} />
                            <Name>Shota Arai</Name>
                            <College>University of Tokyo</College>
                            <Flag country = 'JP'/>
                        </Card>
                        <Card>
                            <Pic src = {yuko} />
                            <Name>Yuko Fujinaga</Name>
                            <College>University of Tokyo</College>
                            <Flag country = 'JP'/>
                        </Card>
                    </CardWrap>
                    <CardWrap>
                        <Card>
                            <Pic src = {yusuke} />
                            <Name>Yusuke Kumaki</Name>
                            <College>University of Tokyo</College>
                            <Flag country = 'JP'/>
                        </Card>
                        <Card>
                            <Pic src = {naoka} />
                            <Name>Naoko MOTOHATA</Name>
                            <College>University of Tokyo</College>
                            <Flag country = 'JP'/>
                        </Card>
                        <Card>
                            <Pic src = {saya} />
                            <Name>Saya Miyake</Name>
                            <College>Keio Girls Senior High School</College>
                            <Flag country = 'JP'/>
                        </Card>
                        <Card>
                            <Pic src = {gil} />
                            <Name>Gil P Padit III</Name>
                            <College>College</College>
                            <Flag country = 'JP'/>
                        </Card> 
                    </CardWrap>
            </Section>
            <Section>
                <Title>{content.r}</Title>
                <CardWrap>
                        <Card>
                            <Pic src = {swetha} />
                            <Name>Swetha Soundararajan</Name>
                            <College>University of Tokyo</College>
                            <FlagWrap>
                            <Flag country = 'IN'/>
                            <Flag country = 'JP'/>
                            </FlagWrap>
                        </Card>
                        <Card>
                            <Pic src = {kam} />
                            <Name>Kamongla Longkumer</Name>
                            <College>National Law University and Judicial Academy, Assam</College>
                            <Flag country = 'IN'/>
                        </Card>
                        <Card>
                            <Pic src = {ishita} />
                            <Name>Ishita Agarwal</Name>
                            <College>TIET, Patiala</College>
                            <Flag country = 'IN'/>
                        </Card>
                        <Card>
                            <Pic src = {karan} />
                            <Name>Karanbir Singh</Name>
                            <College>Thapar University</College>
                            <Flag country = 'IN'/>
                        </Card>
                    </CardWrap>
            </Section>
            
        </Container>
        </>
    )
}

export default TeamPage
