import React from 'react'
import { NGOCard, NGOContainer, NGOH1, NGOH2, NGOIcon, NGOWrapper, StyledLink, NGOContentWrap, ArrowLink } from './NGOsElements'
import { AiFillRightCircle } from "react-icons/ai";

const NGO1 = "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622320471/tokyo/GMVS_jgim9u.jpg";
const NGO2 = "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622320473/tokyo/HRDS_old_hmbo4r.jpg";
const NGO3 = "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622320475/tokyo/Impulse_tovmci.jpg";
const NGO4 = "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622320480/tokyo/Parmarth_dkfa6i.jpg";
const NGO5 = "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622404610/tokyo/Samanta_dmfah2.png";


const NGOs = (props) => {
    let content = {
        English: {
            title: "MEET THE NGOs WE ARE SUPPORTING",

        },
        Japanese: {
            title: "我々が支援するNGOの紹介",
        }
      };
      props.language === "Japanese" ? (content = content.Japanese) : (content = content.English);

    return (
        <NGOContainer id='ngos'>
            <NGOH1>{content.title}</NGOH1>
            <NGOWrapper>
            <StyledLink to = '/gmvs'>
                <NGOCard>
                    <NGOIcon src={NGO1}/>
                    <NGOContentWrap>
                    <NGOH2>Gramin Mahila Vikas Sansthan</NGOH2>
                    <ArrowLink to='/gmvs' smooth={true} duration ={500} spy={true} exact={true} offset={-80}><AiFillRightCircle/></ArrowLink>
                    </NGOContentWrap>
                </NGOCard>
                </StyledLink>
            <StyledLink to = '/hrds'>
                <NGOCard>
                    <NGOIcon src={NGO2}/>
                    <NGOContentWrap>
                    <NGOH2>HRDS India</NGOH2>
                    <ArrowLink to='/hrds' smooth={true} duration ={500} spy={true} exact={true} offset={-80}><AiFillRightCircle/></ArrowLink>
                    </NGOContentWrap>
                </NGOCard>
                </StyledLink>
                <StyledLink to = '/ingon'>
                <NGOCard>
                    <NGOIcon src={NGO3}/>
                    <NGOContentWrap>
                    <NGOH2>Impulse NGO Network </NGOH2>
                    <ArrowLink to='/ingon' smooth={true} duration ={500} spy={true} exact={true} offset={-80}><AiFillRightCircle/></ArrowLink>
                    </NGOContentWrap>
                </NGOCard>
                </StyledLink>
                <StyledLink to = '/parmarth'>
                <NGOCard>
                    <NGOIcon src={NGO4}/>
                    <NGOContentWrap>
                    <NGOH2>Parmarth Samaj Sevi Sansthan</NGOH2>
                    <ArrowLink to='/parmarth' smooth={true} duration ={500} spy={true} exact={true} offset={-80}><AiFillRightCircle/></ArrowLink>
                    </NGOContentWrap>
                </NGOCard>
                </StyledLink>
                <StyledLink to = '/samanta'>
                <NGOCard>
                    <NGOIcon src={NGO5}/>
                    <NGOContentWrap>
                    <NGOH2>Samanta Foundation</NGOH2>
                    <ArrowLink to='/samanta' smooth={true} duration ={500} spy={true} exact={true} offset={-80}><AiFillRightCircle/></ArrowLink>
                    </NGOContentWrap>
                </NGOCard>
                </StyledLink>
            </NGOWrapper>
        </NGOContainer>
    )
}

export default NGOs
