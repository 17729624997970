import React, { useState } from 'react'
import NGOPage from '../components/NGOPage/GMVS'
import { NGO1Data } from '../components/NGOPage/Data'
import Navbar2 from '../components/Navbar2'
import Sidebar2 from '../components/Sidebar2'
import Footer from '../components/Footer'

const NGO1 = () => {
    let languageStoredInLocalStorage = localStorage.getItem("language");
  let [language, setLanguage] = useState(
    languageStoredInLocalStorage ? languageStoredInLocalStorage : "English"
  );
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }
    return (
        <>
            <Sidebar2 isOpen={isOpen} toggle={toggle} />
            <Navbar2 toggle={toggle} language={language}
        handleSetLanguage={language => {
          setLanguage(language);
          storeLanguageInLocalStorage(language);
        }}/>
            <NGOPage {...NGO1Data} language={language}/>
            <Footer language={language}/>
        </>
    )
    function storeLanguageInLocalStorage(language) {
        localStorage.setItem("language", language);
      }
}

export default NGO1
