import React from 'react'
import { NGOHeaderWrap, NGOIcon, NGOInfo, NGOName, NGOPageContainer1, NGOPageContainer2, NGOLeft, NGORight, NGOContentColumn1,NGOContentColumn2, NGOCovidInfoH1, NGOCovidInfop, NGOCardWrap, NGOPageContainer3, NGOPageContainer4, NGOPageContainer5, NGOPageContainer6, Red, NGOQuote, NGOCovidCard, NGOCardWrap2, NGOMap, NGOPageContainer7, NGOAwards } from './NGOPageElements'
import CovidTable from './CovidTable'
import AboutTable from './AboutTable'
import ItemTable from './ItemTable'
import Slideshow from './Slideshow'
import JsxParser from 'react-jsx-parser'
import './styles.css'



const NGOPage = ({ iconSrc, card1, card2, card3, map, awards, language }) => {
    let content = {
        English: {
            head1: "Community served: Rural and tribal communities",
            head2: "Area of operation: Kerala, Tamil Nadu, Gujarat, Tripura, Assam, and Jharkhand",
            head3: "Estimated cost of COVID relief project: USD 6,000/ JPY 650,000/ INR 4,35,000",
            title1: "CURRENT COVID RELIEF PROPOSAL",
            para1: `HRDS is extending the application of <a href ="https://hrdsindia.org/AAGNEYA%20-%20COVID%20PROJECT%20REPORT.php">Project Aagneya</a> to<Red> tribal communities</Red> in the Attappadi region of Kerala. This includes conducting <Red>awareness programs</Red> with school students and providing a <Red>nutrition-rich diet, masks and sanitizers </Red>to marginalised communities including Scheduled Tribes and Scheduled Castes. `,
            para2: `Our support of USD 6,000/ JPY 650,000/ INR 4,35,000 would enable HRDS to provide <Red>food kits and sanitation kits</Red> to <Red>200 families.</Red>`,
            title2: "OVERVIEW",
            para3: `HRDS INDIA is a registered NGO in Palakkad, Kerala striving for the <Red>upliftment and liberation of the underprivileged, especially tribal communities.</Red> Their projects are centered around:`,
            para4: `• Building <a href='https://hrdsindia.org/HRDS-NGO-Projects.php'>environmentally sustainable houses</a> for tribal people`,
            para5: `• Supporting local tribes to <a href='https://hrdsindia.org/karshaka.php'>cultivate medicinal plants</a> in plain lands`,
            para6: "• Empowering poverty-stricken women through SHG (Self-help groups)",
            para7: "• Conducting skill development programs for rural and tribal youths",
            title3: "ABOUT HRDS",
            title4: "EFFORTS SINCE MARCH 2020",
            para8: `Through Project <a href ="https://hrdsindia.org/AAGNEYA%20-%20COVID%20PROJECT%20REPORT.php">Aagneya </a> HRDS aims to provide a <Red>nutritional-rich diet, masks, sanitizers, </Red>to marginalized communitiesincluding Scheduled Tribes and Scheduled Castes, and help the health care workers in the marginalized regions of Navsari, Panchmahal, Dahod, Chhota Udepur Districts of Gujarat for 3 months.`,
            para9: "HRDS provided 3700 food kits to people in Attapaddi region of Kerala.",
            title5: "AWARDS & MEDIA COVERAGE",
            para10: `Their work has been covered by renowned newspapers including <a href='http://epaper.newindianexpress.com/c/36272834'>The New Indian Express</a>(2019), The Times of India, <a href='https://issuu.com/uniquetimesmagazine/docs/low__1_-_eng-nov_2020/12'>Unique Times</a>(2020) and <a href='https://www.theweek.in/wire-updates/business/2019/01/09/pwr9--hrds.html'>The Week </a> (2020).`,
            quote: `<blockquote><p>We believe that we can defeat COVID-19 with the cooperative support of institutions and NGOs like HRDS.</p><footer>Vellingiri, ITDP (Integrated Tribal Development Project) hostel staff</footer></blockquote>`,
            
        },
        Japanese: {
            head1: "担当するコミュニティ：農村や部族のコミュニティ",
            head2: "活動地域：ケーララ州、タミル・ナードゥ州、グジャラート州、トリプラ州、アッサム州、ジャールカンド州",
            head3: "コロナ支援策の想定される費用：USD 6,000/ JPY 650,000/ INR 4,35,000",
            title1: "現在のコロナ支援案",
            para1: `HRDSはケーララ州のAttappadi地域で<a href ='https://hrdsindia.org/AAGNEYA%20-%20COVID%20PROJECT%20REPORT.php'>「Aagneya」</a>というプロジェクトの<Red>部族</Red>への適用の拡大をしています。学校の生徒との<Red>意識向上活動</Red>の実施や、指定部族と指定カーストを含む、社会から取り残されたコミュニティへの<Red>栄養豊富な食事、マスクと消毒</Red>薬の配布を行っています。`,
            para2: `支援の65万円によりHRDSが<Red>食糧キット</Red>と<Red>衛生キット</Red>を<Red>200世帯</Red>に配給することが可能になるでしょう。`,
            title2: "概要",
            para3: `HRDS INDIAはパラカッド地区やケーララ州で社会的、経済的に恵まれない人たち、特に<Red>部族の人たちの生活改善と解放</Red>のために尽力するNGOです。HRDSのプロジェクトが中心に行っていること：`,
            para4: `• 部族のための<a href='https://hrdsindia.org/HRDS-NGO-Projects.php'>環境的に持続可能な住居</a>の建設`,
            para5: `• 地域部族が平地で<a href='https://hrdsindia.org/karshaka.php'>薬草の栽培</a>をするサポート`,
            para6: "• 自助グループを通し、貧困に苦しんでいる女性たちへの力付け",
            para7: "• 農村や部族の若者のための能力開発プログラムの実施",
            title3: "HRDSについて",
            title4: "2020年3月以降の活動成果",
            para8: `<a href ='https://hrdsindia.org/AAGNEYA%20-%20COVID%20PROJECT%20REPORT.php'>Aagneya</a>プロジェクトを通してHRDSは、指定部族と指定カーストを含む、社会から取り残されたコミュニティへの<Red>栄養豊富な食事、マスクと消毒薬</Red>の配布や、3ヶ月間、<Red>社会から取り残された</Red>グジャラート州のNavsariやPanchmahal、Dahod、Chhota、Chhota Udepur地域で医療従事者の手伝いをした。`,
            para9: "HRDSは<Red>3700の食糧キット</Red>をケーララ州のAttapaddi地域の人々に提供しました。",
            title5: "受賞とメディアでの報道",
            para10: `HRDSの活動は <a href='http://epaper.newindianexpress.com/c/36272834'>The New Indian Express</a>(2019)やThe Times of Iindia、<a href='https://issuu.com/uniquetimesmagazine/docs/low__1_-_eng-nov_2020/12'>Unique Times</a>(2020) 、 <a href='https://www.theweek.in/wire-updates/business/2019/01/09/pwr9--hrds.html'>The Week </a> (2020)などの有名な新聞に報道されてきました。`,
            quote: `<blockquote><p>私たちはHRDSのような団体やNGOの協力的なサポートによってCOVID-19に打ち勝つことができると信じている」</p><footer>Vellingiri, ITDP　(Integrated Tribal Development Project) ホステルのスタッフ</footer></blockquote>`,
        }
      };
      language === "Japanese" ? (content = content.Japanese) : (content = content.English);
    return (
        <>
            <NGOPageContainer1>
                <NGOHeaderWrap>
                    <NGOLeft>
                        <NGOIcon src={iconSrc}/>
                    </NGOLeft>
                    <NGORight>
                        <NGOName>HRDS INDIA (The Highrange Rural Development Society)</NGOName>
                        <NGOInfo>{content.head1}</NGOInfo>
                        <NGOInfo>{content.head2}</NGOInfo>
                        <NGOInfo>{content.head3}</NGOInfo>
                    </NGORight>
                </NGOHeaderWrap>
            </NGOPageContainer1>

            <NGOPageContainer2>
                    <NGOContentColumn1>
                        <NGOCovidInfoH1>{content.title1}</NGOCovidInfoH1>
                        <NGOCovidInfop><JsxParser jsx={content.para1} components={{ Red }}/></NGOCovidInfop>
                        <NGOCovidInfop><JsxParser jsx={content.para2} components={{ Red }}/></NGOCovidInfop>
                        <CovidTable language={language}/>
                    </NGOContentColumn1>
                    <NGOContentColumn2>
                        <NGOCardWrap>
                            <NGOCovidCard src ={card1}/>
                            <NGOCovidCard src ={card2}/>
                            </NGOCardWrap>
                        <NGOCardWrap2>
                            <NGOCovidCard src ={card3}/>
                        </NGOCardWrap2>
                    </NGOContentColumn2>
            </NGOPageContainer2>

            <NGOPageContainer3>
                    <NGOContentColumn1>
                    <NGOCovidInfoH1>{content.title2}</NGOCovidInfoH1>
                        <NGOCovidInfop><JsxParser jsx={content.para3} components={{ Red }}/></NGOCovidInfop>
                        <NGOCovidInfop><JsxParser jsx={content.para4} components={{ Red }}/></NGOCovidInfop>
                        <NGOCovidInfop><JsxParser jsx={content.para5} components={{ Red }}/></NGOCovidInfop>
                        <NGOCovidInfop><JsxParser jsx={content.para6} components={{ Red }}/></NGOCovidInfop>
                        <NGOCovidInfop><JsxParser jsx={content.para7} components={{ Red }}/></NGOCovidInfop>
                    </NGOContentColumn1>
                    <NGOContentColumn2>
                    <Slideshow language={language}/>
                    </NGOContentColumn2>
            </NGOPageContainer3>

            <NGOPageContainer4>
                    <NGOContentColumn1>
                        <NGOCovidInfoH1>{content.title3}</NGOCovidInfoH1>
                        <AboutTable language={language}/>
                    </NGOContentColumn1>
                    <NGOContentColumn2>
                        <NGOMap src = {map}/>
                        
                    </NGOContentColumn2>
            </NGOPageContainer4>

            <NGOPageContainer5>
                    <NGOContentColumn1>
                    <NGOCovidInfoH1>{content.title4}</NGOCovidInfoH1>
                    <NGOCovidInfop><JsxParser jsx={content.para8} components={{ Red }}/></NGOCovidInfop>
                    <NGOCovidInfop><JsxParser jsx={content.para9} components={{ Red }}/></NGOCovidInfop>
                    </NGOContentColumn1>
                    <NGOContentColumn2>
                    <ItemTable language={language}/>
                    </NGOContentColumn2>
            </NGOPageContainer5>

            <NGOPageContainer6>
                <NGOContentColumn1>
                    <NGOCovidInfoH1>{content.title5}</NGOCovidInfoH1>
                    <NGOCovidInfop><JsxParser jsx={content.para10} components={{ Red }}/></NGOCovidInfop>
                    </NGOContentColumn1>
                    <NGOContentColumn2>
                    <NGOQuote><JsxParser jsx={content.quote}/></NGOQuote>
                </NGOContentColumn2>
            </NGOPageContainer6>
            
            <NGOPageContainer7> <NGOAwards src={awards}/></NGOPageContainer7>
           
    </>
    )
}

export default NGOPage
