import React from 'react'
import { Button } from '../ButtonElement'
import JsxParser from 'react-jsx-parser'
import { Heading, Img, ImgWrap, InfoColumn1, InfoColumn2, InfoContainer, InfoRow, InfoWrapper, Subheading, TextWrapper, Title, StyledLink, BtnWrap, Bold } from './MissionElements'


const Mission = (props) => {
    let content = {
        English: {
            title: "Our Mission",
            head: "SUPPORT INDIA AS GLOBALLY-MINDED CITIZENS",
            para1: `<Bold>India is currently facing the world’s worst COVID-19 outbreak.</Bold> The pandemic has now reached marginalised communities in villages, who have almost <StyledLink href ='https://edition.cnn.com/2021/05/11/india/india-covid-gujarat-village-intl-hnk/index.html'>no access to doctors and hospitals.</StyledLink> These communities lived in a precarious state even before the pandemic began and are now suffering a humanitarian crisis in parallel with the medical emergency that has taken over the nation.`,
            para2: `We have launched this fundraiser as a call to action for all globally-minded citizens. Together with NPO Asia Initiatives, we will distribute the funds raised to five local NGOs undertaking COVID-relief activities for marginalised populations in different parts of rural India. We have built a detailed profile for each of these NGOs that documents the meticulous <StyledLink href ='https://docs.google.com/document/d/1-r7ODXj2nTxiuYA00jBQ3FDPq9uxON-wsR6lk4NNubk/edit?usp=sharing'>internal verification process</StyledLink> followed by our team to select these organisations.If we reach our goal before the end of the campaign, we will expand the initiative to support other marginalised populations in different parts of rural India.`,
            button: "Know More",
            img: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1621785378/tokyo/map_english_vqeuue.png",

        },
        Japanese: {
            title: "私たちのミッション",
            head: "国際的視野を持つ市民としてのインドの農村の支援",
            para1: `<Bold>新型コロナウイルス感染症は、2021年4月以来インドで猛威を振るっています。</Bold> 新型コロナウイルスは、<StyledLink href = "https://www3.nhk.or.jp/news/html/20210510/k10013020861000.html">医師や病院へのアクセスが困難</StyledLink>な村や、社会の周縁に追いやられた<StyledLink href ="https://www.afpbb.com/articles/-/3346788">地域にまで急速に広がっています。</StyledLink>これらの地域にあるコミュニティは、コロナウイルスが始まる前から不安定な状態で生活しており、現在、インド全国を振るった医療緊急事態と並行して、人道的危機に陥っています。`,
            para2: "私たちは全ての国際的視野を持つ市民への行動喚起としてこの募金活動に着手しています。NPO法人アジア・イニシアチブズとともに、インドのさまざまな地域で社会から取り残された人々のために、コロナ支援策を行っている5つのNGOに寄付金を分配していきます。当チームがこれらの団体を選ぶために行った綿密な内部検証プロセスを記録した各NGOの詳細なプロフィールを作りました。このキャンペーンの終わりまでに目標に達した場合、他のインドのさまざまな地域で社会から取り残された人々を支援する取り組みを広げます。",
            button: "詳細はこちら",
            img: "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1622847709/tokyo/map_japanese_alftpw.png"
        }
      };
      props.language === "Japanese" ? (content = content.Japanese) : (content = content.English);
    return (
        <>
            <InfoContainer lightBg={true} id={"mission"}>
                <InfoWrapper>
                    <InfoRow imgStart={false}>
                        <InfoColumn1>
                            <TextWrapper>
                                <Title>{content.title}</Title>
                                <Heading lightText={false}>{content.head}</Heading>
                                <Subheading><JsxParser jsx={content.para1} components={{ StyledLink, Bold }}/></Subheading>
                                <Subheading><JsxParser jsx={content.para2} components={{ StyledLink }}/></Subheading>
                            </TextWrapper>
                            <BtnWrap>
                                    <Button to='/mission' primary={false ? 1: 0} dark={false ? 1: 0}> {content.button}</Button>
                                </BtnWrap>s
                        </InfoColumn1>
                        <InfoColumn2>
                            <ImgWrap>
                                <Img src={content.img}/>
                            </ImgWrap>
                        </InfoColumn2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
            
        </>
    )
}

export default Mission
