import React, {useState, useEffect} from 'react';
import TextTransition from "react-text-transition";
import { Button } from '../ButtonElement';
import { HomeContainer, HomeBg, PhotoBg, HomeBtnWrapper, HomeContent, HomeH1, HomeP} from './HomeElements';
import ModalComponent from '../Modal';
import { ModalProvider } from 'react-simple-hook-modal';


const Photo = "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1621558994/tokyo/landing_a3ca1f.jpg";

const Heading = [
    "YOU CAN HELP SAVE LIVES",
    "命を救うご支援をお願いします",
  ];

const Subheading = [
    "Support marginalised communities across India who have been severely impacted by COVID-19",
    "コロナ禍で命の危険にさらされるインドの脆弱なコミュニティーを支援する",
];
const HomeSection = (props) => {

    const [index, setIndex] = React.useState(0);

    useEffect(() => {
        const intervalId = setInterval(() =>
          setIndex(index => index + 1),
          5000 // every 3 seconds
        );
        return () => clearTimeout(intervalId);
      }, []);

    const [hover, setHover]= useState(false)
    
    const onHover = () => {
        setHover(!hover)
    }
    return (
        <HomeContainer id="home">
            <HomeBg/>
            <PhotoBg src={Photo} alt="Their Toys by Atul Pandey"/>
            <HomeContent>
                <HomeH1><TextTransition text={Heading[index%Heading.length]} springConfig={{ stiffness: 90, damping: 90 }} noOverflow/></HomeH1>
                <HomeP><TextTransition text={Subheading[index%Heading.length]} springConfig={{ stiffness: 90, damping: 90 }} noOverflow/></HomeP>
                <HomeBtnWrapper>
                    <Button onClick={()=> window.location.href='https://milaap.org/fundraisers/support-marginalised-communities-in-india/'} onMounseEnter={onHover} onMouseLeave={onHover} primary='true' dark='false'>Donate Now</Button>
                    <Button onClick={()=> window.location.href='https://camp-fire.jp/projects/view/436736'} onMounseEnter={onHover} onMouseLeave={onHover} primary='true' dark='false'>支援する</Button>
                </HomeBtnWrapper>
                <ModalProvider>
                    <ModalComponent />
                </ModalProvider>
            </HomeContent>
        </HomeContainer>
    )
}
export default HomeSection
