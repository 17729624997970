import styled from 'styled-components'

export const InfoContainer = styled.div`
    color: #fff;
    background: #f9f9f9;

    @media screen and (max-width: 768px) {
        padding: 110px 0;
    }

`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 900px;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 30px;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        height: 1100px;
        padding: 0 24px;
    }
`;

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax()(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};
    justify-content: center;


    @media screen and (max-width: 768px) {
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1'  'col2'` : `'col1 col1' 'col2 col2'`)};
    }
`;

export const InfoColumn1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
`;

export const InfoColumn2 = styled.div`
    margin-bottom: 15px;
    padding: 0 30px;
    grid-area: col2;
`;

export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
    margin-left: 60px;

    @media screen and (max-width: 480px) {
        margin-left: 0;
        padding-left: 20px;
        padding-right: 20px;
    }
`;

export const Title = styled.p`
    color: #D13E1B;
    font-size: 18px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;

`;

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1.%;
    color: #000;

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

export const Subheading = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: #010606;
`;

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const ImgWrap = styled.div`
    max-width: 540px;
    align-items: center;
    justify-content: space-evenly;
    display: flex;
    flex-direction: row;
    margin-top: 30px;

`;

export const Img = styled.img`
    width: 75%;
`;

export const SocialIconLink = styled.a`
    color: #0c0c0c;
    font-size: 40px;
`;