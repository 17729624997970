import React from 'react'
import { NGOHeaderWrap, NGOIcon, NGOInfo, NGOName, NGOPageContainer1, NGOPageContainer2, NGOLeft, NGORight, NGOContentColumn1,NGOContentColumn2, NGOCovidInfoH1, NGOCovidInfop, NGOPageContainer3, NGOPageContainer4, NGOPageContainer5, NGOPageContainer6, Red, NGOQuote, NGOPageContainer7, NGOAwards, NGOCardWrap, NGOCovidCard, NGOMap } from './NGOPageElements'
import CovidTable from './CovidTable'
import AboutTable from './AboutTable'
import ItemTable from './ItemTable'
import Slideshow from './Slideshow'
import JsxParser from 'react-jsx-parser'
import './styles.css'

const NGOPage = ({ iconSrc, card1, card2, map, awards, language }) => {
    let content = {
        English: {
            head1: "Community served: Victims of human trafficking, rural and vulnerable communities",
            head2: "Area of operation: North-East India, North Bengal, Bangladesh, Nepal, Myanmar",
            head3: "Estimated cost of COVID relief project: USD 8,000/ JPY 870,000/ INR 5,80,000",
            title1: "CURRENT COVID RELIEF PROPOSAL",
            para1: `Our donation drive would help INGON reach 750 households with <Red>ration kits</Red> and <Red>sanitation kits</Red> across 8 States in North-Eastern India.`,
            para2: `This is one of the most marginalised regions of India and thus a crucial area for our initiative’s outreach.`,
            title2: "OVERVIEW",
            para3: `Impulse NGO Network started with the purpose of addressing <Red>trafficking of children and women</Red> from the North-Eastern region of India for <Red>sexual exploitation and forced labor.</Red> `,
            para4: `Today, INGON has extended its reach not only to different parts of India, but also across the world, and is actively working with its partners in combating and solving human trafficking and exploitation worldwide, by making use of the globally acknowledged <a href='https://impulsengonetwork.org/our-work/impulse-model/'>“Impulse Model.”</a> `,
            title3: "ABOUT INGON",
            title4: "EFFORTS SINCE MARCH 2020",
            para5: `Besides distributing <a href= 'https://www.facebook.com/ImpulseNgoNetwork/photos/pcb.4148627761869659/4148626295203139/'>sanitary kits</a> and <a href ='https://www.facebook.com/ImpulseNgoNetwork/photos/pcb.3870838726315232/3870837002982071/'>food and ration packages</a> to vulnerable communities, INGON <Red>donated a Sanitary napkin vending machine</Red> to Lumparing Vidyapith Secondary School in Shillong. Impulse has also launched an initiative named <Red>“1 million meals”</Red> with the support of <a href ='https://apneaap.org/'>Apne Aap Women Worldwide</a> in January 2021 as part of the “Impulse Model Prevention Co-Creation Rural Futures.”`,
            title5: "AWARDS & MEDIA COVERAGE",
            para6: `INGON has been the recipient of several awards and has been accorded recognition and honored by various global institutions like <a href='https://www.unodc.org'>United Nation Office on Drugs and Crimes</a> and <a href='https://www1.undp.org/content/oslo-governance-centre/en/home/library/what-is-good-practice.html'>UNDP</a> for its relentless and exemplary fight against human trafficking and child labor. INGON’s work has been extensively covered by <a href='https://www.readersdigest.in/features/story-extraordinary-indians-124790'>Reader’s Digest </a>(2020),  <a href='https://www.thebetterindia.com/160904/human-trafficking-northeast-impulse-ngo/'>The Better India </a>(2018) , <a href='https://www.reuters.com/article/us-india-trafficking-rohingya/lured-with-a-happily-ever-after-dream-rohingya-girls-sold-in-india-idUSKBN1FB1LD'>Reuters India </a>(2018), <a href='https://www.hindustantimes.com/india-news/myanmar-emerges-as-transit-hub-for-trafficking-northeast-girls-to-south-east-asia/story-G4K09Iaq3Tgneu1hDplRVM.html'>Hindustan Times</a> (2017), <a href='https://www.theguardian.com/social-enterprise-network/2014/jun/18/women-social-entrepreneurs-conflict-zones'>The Guardian</a> (2014) and <a href='https://india.blogs.nytimes.com/2012/01/17/hasina-kharbhih-raising-the-stakes-against-child-trafficking/?_r=2'>The New York Times</a> (2012). The full list of media/newspaper coverage can be accessed <a href ='https://impulsengonetwork.org/our-work/impulse-model/media-coverage-of-impulse-model/#2020'>here.</a>`,
            quote: `<blockquote><p>Impulse NGO took action and distributed relief materials to 500 families whose livelihoods were disrupted by COVID-19.</p><footer> Ranjeet Doley and Rekha Doley (Impulse Social Enterprises Representatives)</footer></blockquote>`

            
        },
        Japanese: {
            head1: "担当するコミュニティ：人身売買の被害者、農村コミュニティ",
            head2: "活動地域：北東インド、北ベンガル、バングラデシュ、ネパール、ミャンマー",
            head3: "コロナ支援策の想定される費用：USD 8,000/ JPY 870,000/ INR 5,80,000",
            title1: "現在のコロナ支援策",
            para1: `私たちの支援金で、INGONは<Red>北東インド8州</Red>の<Red>750世帯</Red>に、<Red>食糧キット</Red>と<Red>衛生キット</Red>を届けることができます。`,
            para2: `この地域は、インドの中でも最も周縁化された地域の一つで、今回の活動で救済すべき重要な地域です。`,
            title2: "概要",
            para3: `インパルス・NGO・ネットワーク(INGON)は、インド北東地域で起こっている、<Red>性的搾取や強制労働</Red>のための<Red>子供や女性の人身売買</Red>を解決するべく活動を開始しました。`,
            para4: `今日では、INGONはインド各地だけでなく、世界中に活動地域を広げています。そして、協力団体とともに、積極的に人身売買や搾取の問題に立ち向かい、解決を目指して取り組みを行っています。活動は、国際的にも認められている<a href='https://impulsengonetwork.org/our-work/impulse-model/'>インパルス・モデル</a> に則っています。`,
            title3: "INGONについて",
            title4: "2020年3月以降の活動成果",
            para5: `貧困地域に<a href = 'https://www.facebook.com/ImpulseNgoNetwork/photos/pcb.4148627761869659/4148626295203139/'>衛生キット</a>や<a href ='https://www.facebook.com/ImpulseNgoNetwork/photos/pcb.3870838726315232/3870837002982071/'>食糧キット</a>を詰め合わせたものを配布してきました。それに加えて、INGONはシロン(メガラヤ州都)にあるLumparing Vidyapith中学校に、<Red>生理用ナプキン</Red>の販売機を設置しました。さらに、 <a href ='https://apneaap.org/'>Apne Aap Women Worldwide</a>の協力を得た新たな援助策として、2021年1月に<Red>「100万人分の食事」</Red>を始めました。これは、「インパルス・モデル・プリベンション・コークリエイション・ルーラル・フューチャーズ(インパルスモデルで田舎の未来を守り、共創しよう)」の活動の一環です。`,
            title5: "受賞とメディアでの報道",
            para6: `INGONは、その歩みを止めることなく人身売買や児童労働と果敢に闘い続けてきました。その結果として数々の賞を受賞し、<a href ='https://www.unodc.org'>国連薬物犯罪事務所</a> や<a href ='https://www1.undp.org/content/oslo-governance-centre/en/home/library/what-is-good-practice.html'>国連開発計画</a>など様々な国際機関から評価を得てきました。INGONの活動は、<a href ='https://www.readersdigest.in/features/story-extraordinary-indians-124790'>リーダーズ・ダイジェスト</a> (2020) 、<a href = 'https://www.thebetterindia.com/160904/human-trafficking-northeast-impulse-ngo/'>ベター・インディア  </a>(2018)、<a href ='https://www.reuters.com/article/us-india-trafficking-rohingya/lured-with-a-happily-ever-after-dream-rohingya-girls-sold-in-india-idUSKBN1FB1LD'>ルーターズ・インディア</a>(2018)、<a href ='https://www.hindustantimes.com/india-news/myanmar-emerges-as-transit-hub-for-trafficking-northeast-girls-to-south-east-asia/story-G4K09Iaq3Tgneu1hDplRVM.html'>ヒンディスタン・タイムズ  </a>(2017)、<a href = 'https://www.theguardian.com/social-enterprise-network/2014/jun/18/women-social-entrepreneurs-conflict-zones'>ガーディアン</a> (2014) 、<a href ='https://india.blogs.nytimes.com/2012/01/17/hasina-kharbhih-raising-the-stakes-against-child-trafficking/?_r=2'>ニューヨーク・タイムズ </a>(2012)などで大きく報道されました。メディア/新聞掲載情報一覧は、<a href ='https://impulsengonetwork.org/our-work/impulse-model/media-coverage-of-impulse-model/#2020'>こちら</a>でご覧になれます。`,
            quote: `<blockquote><p>Impulse NGOは、アクションを起こし、新型コロナウイルスによって生活の維持が困難になった500もの家族に救援物資を届けました。</p><footer> — ランジート・ドーリー、レカ・ドーリー (Impulse社会事業部代表)</footer></blockquote>`
        }
      };
      language === "Japanese" ? (content = content.Japanese) : (content = content.English);
    return (
        <>
            <NGOPageContainer1>
                <NGOHeaderWrap>
                    <NGOLeft>
                        <NGOIcon src={iconSrc}/>
                    </NGOLeft>
                    <NGORight>
                        <NGOName>IMPULSE NGO NETWORK (INGON)</NGOName>
                        <NGOInfo><JsxParser jsx={content.head1} components={{ Red }}/></NGOInfo>
                        <NGOInfo><JsxParser jsx={content.head2} components={{ Red }}/></NGOInfo>
                        <NGOInfo><JsxParser jsx={content.head3} components={{ Red }}/></NGOInfo>
                    </NGORight>
                </NGOHeaderWrap>
            </NGOPageContainer1>

            <NGOPageContainer2>
                    <NGOContentColumn1>
                        <NGOCovidInfoH1><JsxParser jsx={content.title1}/></NGOCovidInfoH1>
                        <NGOCovidInfop><JsxParser jsx={content.para1} components={{ Red }}/></NGOCovidInfop>
                        <NGOCovidInfop><JsxParser jsx={content.para2} components={{ Red }}/></NGOCovidInfop>
                        <CovidTable language={language} />
                    </NGOContentColumn1>
                    <NGOContentColumn2>
                        <NGOCardWrap>
                            <NGOCovidCard src ={card1}/>
                            <NGOCovidCard src ={card2}/>
                        </NGOCardWrap>
                    </NGOContentColumn2>
            </NGOPageContainer2>

            <NGOPageContainer3>
                    <NGOContentColumn1>
                    <NGOCovidInfoH1><JsxParser jsx={content.title2} components={{ Red }}/></NGOCovidInfoH1>
                        <NGOCovidInfop><JsxParser jsx={content.para3} components={{ Red }}/></NGOCovidInfop>
                        <NGOCovidInfop><JsxParser jsx={content.para4} components={{ Red }}/></NGOCovidInfop>
                    </NGOContentColumn1>
                    <NGOContentColumn2>
                    <Slideshow language={language}/>
                    </NGOContentColumn2>
            </NGOPageContainer3>

            <NGOPageContainer4>
                    <NGOContentColumn1>
                        <NGOCovidInfoH1><JsxParser jsx={content.title3} components={{ Red }}/></NGOCovidInfoH1>
                        <AboutTable language={language}/>
                    </NGOContentColumn1>
                    <NGOContentColumn2>
                        <NGOMap src = {map}/>
                        
                    </NGOContentColumn2>
            </NGOPageContainer4>

            <NGOPageContainer5>
                    <NGOContentColumn1>
                    <NGOCovidInfoH1><JsxParser jsx={content.title4} components={{ Red }}/></NGOCovidInfoH1>
                    <NGOCovidInfop><JsxParser jsx={content.para5} components={{ Red }}/></NGOCovidInfop>
                    </NGOContentColumn1>
                    <NGOContentColumn2>
                        <ItemTable language={language}/>
                    </NGOContentColumn2>
            </NGOPageContainer5>

            <NGOPageContainer6>
                <NGOContentColumn1>
                    <NGOCovidInfoH1><JsxParser jsx={content.title5} components={{ Red }}/></NGOCovidInfoH1>
                    <NGOCovidInfop><JsxParser jsx={content.para6} components={{ Red }}/></NGOCovidInfop>
                    </NGOContentColumn1>
                    <NGOContentColumn2>
                    <NGOQuote><JsxParser jsx={content.quote} components={{ Red }}/></NGOQuote>
                </NGOContentColumn2>
                
            </NGOPageContainer6>
            <NGOPageContainer7> <NGOAwards src={awards}/></NGOPageContainer7>
           
    </>
    )
}

export default NGOPage
