import React from 'react';
import { Table, Tr, Td } from 'react-super-responsive-table';
import './CovidTable.css'

export default function ItemTable({i, language}) {
  let content = {
    English: {
        t1: "Item Distributed",
        t2: "Quantity",
        h1: "Food Kits",
        h2: "Masks",
        h3: "Sanitizer",

        
    },
    Japanese: {
      t1: "供給物",
      t2: "数量(3ヶ月で1000家庭)",
      h1: "食糧キット",
      h2: "マスク",
      h3: "消毒薬",
    }
  };
  language === "Japanese" ? (content = content.Japanese) : (content = content.English);
  return (
    <Table key={i}>
      <Tr class='head'>
        <Td>{content.t1}</Td>
        <Td>{content.t2}</Td>
        </Tr>
        <Tr class='row'>
          <Td>{content.h1}</Td>
          <Td>3000</Td>
        </Tr>
        <Tr class='row'>
          <Td>{content.h2}</Td>
          <Td>12000</Td>
        </Tr>
        <Tr class='row'>
          <Td>{content.h3}</Td>
          <Td>3000</Td>
        </Tr>
    </Table>
  );
}
