import React from 'react';
import { Table, Tr, Td } from 'react-super-responsive-table';
import './CovidTable.css'

export default function ItemTable({i, language}) {
  let content = {
    English: {
        t1: "Item Distributed",
        t2: "Quantity",
        h1: "Sanitary Kits",
        h2: "Food Meals (1 Million Meals)",
        h3: "Sanitary Napkin Vending Machine",
        
    },
    Japanese: {
      t1: "供給物",
      t2: "数量",
      h1: "衛生キット",
      h2: "食糧品 (100万人分のごはん)",
      h3: "生理用ナプキン販売機",
        
        
    }
  };
  language === "Japanese" ? (content = content.Japanese) : (content = content.English);
  return (
    <Table key={i}>
      <Tr class='head'>
        <Td>{content.t1}</Td>
        <Td>{content.t2}</Td>
        </Tr>
        <Tr class='row'>
          <Td>{content.h1}</Td>
          <Td>900</Td>
        </Tr>
        <Tr class='row'>
          <Td>{content.h2}</Td>
          <Td>1000000</Td>
        </Tr>
        <Tr class='row'>
          <Td>{content.h3}</Td>
          <Td>1</Td>
        </Tr>
    </Table>
  );
}
