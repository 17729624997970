import React from 'react'
import { NGOHeaderWrap, NGOIcon, NGOInfo, NGOName, NGOPageContainer1, NGOPageContainer2, NGOLeft, NGORight, NGOContentColumn1,NGOContentColumn2, NGOCovidInfoH1, NGOCovidInfop, NGOPageContainer3, NGOPageContainer4,  NGOAwards, NGOPageContainer5, NGOPageContainer6, NGOPageContainer7, Red, NGOCovidCard, NGOQuote, NGORow, NGOMap } from './NGOPageElements'
import CovidTable from './CovidTable'
import AboutTable from './AboutTable'
import ItemTable from './ItemTable'
import Slideshow from './Slideshow'
import JsxParser from 'react-jsx-parser'
import './styles.css'

const NGOPage = ({ iconSrc, card1, card2, map, awards, language }) => {
    let content = {
        English: {
            head1: "Community served: Taungya and Van Gujjar communities (forest communities)",
            head2: "Area of operation: In and around the Rajaji National Park and Tiger Reserve in the Lower Shivaliks in Uttarakhand and Uttar Pradesh",
            head3: "Estimated cost of COVID relief project: USD 4,000/ JPY 430,000/ INR 2,90,000",
            title1: "CURRENT COVID RELIEF PROPOSAL",
            para1: `Samanta has been supporting community owned and youth run learning spaces to ensure continuity of <a href='https://www.samanta.org.in/the-intervention'>education</a> in COVID-19 times through their <a href='https://www.samanta.org.in/project-design'>key programs.</a> They provide <Red>ration kits, sanitation kits</Red> and <Red>medicine kits</Red> while following measures to build long-term <Red>resilience</Red> such as setting up a <Red>nutrition garden</Red> and providing <Red>immunity booster kits.</Red> `,
            para2: `Our support of USD 4,000/ JPY 430,000/ INR 2,90,000 would allow Samanta to reach <Red>200 families.</Red>`,
            title2: "OVERVIEW",
            para3: `Samanta foundation stands for <Red>equal access, availability and affordability</Red> of <Red>opportunities </Red>for all communities and strives for the enablement of communities in the areas of <Red>education, health and livelihood.</Red> Samanta's <a href='https://www.samanta.org.in/who-we-are'>aim</a> is to achieve this through community participation, ownership and indigenous ideation.`,
            para4: `Samanta is also a local partner of Asia Initiatives in India.`,
            title3: "ABOUT SAMANTA",
            title4: "EFFORTS SINCE MARCH 2020",
            para5: `Samanta <Red>travelled more than 600+ kms across</Red> 2 States, 4 Districts to reach 2 Forest Communities, one pastoralist and one peripheral, with an aim to connect with <Red>500+ women and 500+ children.</Red>They developed a <a href='https://www.samanta.org.in/knowledge'>covid handbook</a> for the Community to help them <Red>understand the Corona Virus - the disease and its implications.</Red> They also distributed medicines and sanitary kits.`,
            title5: "AWARDS & MEDIA COVERAGE",
            para6: `Samanta’s work has been covered by India’s largest English national daily—<a href ='https://timesofindia.indiatimes.com/city/bengaluru/city-ngos-to-mentor-8-startups-working-with-schools/articleshow/70761363.cms'>The Times of India </a>(2019). Their work can also be found in their regular <a href='https://www.samanta.org.in/newsletter'>newsletters</a> and on their well-maintained <a href='https://www.samanta.org.in/blog'>blog.</a>`,
            quote: `<blockquote><p>I believe in people and their stories of survival, they inspire me to keep going.</p><footer>Tanya Khera, Co-founder, Samanta Foundation</footer></blockquote>`,
            
        },
        Japanese: {
            head1: "担当するコミュニティ：タウンヤ共同体とヴァン・ガジャー共同体（森林地域）",
            head2: "活動地域：ウッタラーカンド州とウッタラー・プラデーシュ州のローワー・シバリックスにあるラジャジ国立公園とタイガー保護区の周辺にて",
            head3: "コロナ支援プロジェクトのための推定費用：USD 4,000/ JPY 430,000/ INR 2,90,000",
            title1: "現在のコロナ支援案",
            para1: `Samantaは主要なプログラムを通じて、コロナの時代に<a href ='https://www.samanta.org.in/the-intervention'>教育</a>の持続性を確保するため、コミュニティが所有し若者が運営する学習スペースを支援してきました。その<a href ='https://www.samanta.org.in/project-design'>主要プログラム</a>では<Red>食糧キット</Red>や<Red>衛生キット</Red>、<Red>医薬品キット</Red>を支給するだけでなく、長期的な<Red>回復力</Red>を可能とするため、<Red>栄養の供給源となる農地</Red>の整備や<Red>免疫力を高めるキット</Red>の支給などの処置を続けています。`,
            para2: `Samantaの活動によって新たに<Red>200世帯</Red>に支援の手を差し伸べることができます。`,
            title2: "概要",
            para3: `Samanta基金は、すべてのコミュニティが<Red>機会に平等にアクセスし入手できる</Red>状態を目指し、コミュニティの<Red>教育、健康、生活</Red>の面での可能性の実現に励んでいます。この<a href ='https://www.samanta.org.in/who-we-are'>目標</a>を達成するため、Samantaは地域コミュニティの参加や所有者グループ、地元の人々のアイデア形成を通じて活動しています。`,
            para4: `Samantaはインドのアジア・イニシアティブズの国内共同パートナーです。`,
            title3: "Samantaについて",
            title4: "2020年3月以降の活動成果",
            para5: `Samantaは２つの州、４つの地区において<Red>600km以上もの距離を活動してまわり、</Red>２つの森林部のコミュ二ティ、1つの田園地域と1つの外縁地域を訪れ、<Red>500人以上の女性と500人以上の子供たち</Red>と接触することを目指しました。コミュニティのために作られた<a href ='https://www.samanta.org.in/knowledge'>コロナのハンドブック</a>は、人々がコロナについて -<Red>コロナがどのような病気であり何を意味する</Red>のか- 理解するのに役立っています。また同時に、医薬用品や衛生キットの一式も配布されました。`,
            title5: "受賞とメディアでの報道",
            para6: `Samantaの活動はインド最大の英語の全国紙「<a href ='https://timesofindia.indiatimes.com/city/bengaluru/city-ngos-to-mentor-8-startups-working-with-schools/articleshow/70761363.cms'>ザ・タイムズ・オブ・インディア</a>」(2019)で報道されました。Samantaの定期<a href='https://www.samanta.org.in/newsletter'>会報</a> や運営の行き届いた<a href='https://www.samanta.org.in/blog'>ブロッグ</a>でも,、Samantaの活動を見ることができます。`,
            quote: `<blockquote><p>私は人々とその生き残りの物語を信じています。人々は私に前進する勇気を与えてくれるのです。</p><footer>Samanta基金共同創設者タニャ・ケラ氏</footer></blockquote>`,
        }
      };
      language === "Japanese" ? (content = content.Japanese) : (content = content.English);
    return (
        <>
            <NGOPageContainer1>
                <NGOHeaderWrap>
                    <NGOLeft>
                        <NGOIcon src={iconSrc}/>
                    </NGOLeft>
                    <NGORight>
                        <NGOName>Samanta Foundation</NGOName>
                        <NGOInfo><JsxParser jsx={content.head1} components={{ Red }}/></NGOInfo>
                        <NGOInfo><JsxParser jsx={content.head2} components={{ Red }}/></NGOInfo>
                        <NGOInfo><JsxParser jsx={content.head3} components={{ Red }}/></NGOInfo>
                    </NGORight>
                </NGOHeaderWrap>
            </NGOPageContainer1>

            <NGOPageContainer2>
                    <NGOContentColumn1>
                        <NGOCovidInfoH1><JsxParser jsx={content.title1} components={{ Red }}/></NGOCovidInfoH1>
                        <NGOCovidInfop><JsxParser jsx={content.para1} components={{ Red }}/></NGOCovidInfop>
                        <NGOCovidInfop><JsxParser jsx={content.para2} components={{ Red }}/></NGOCovidInfop>
                    </NGOContentColumn1>
                    <NGOContentColumn2>
                    <CovidTable language={language}/>
                    </NGOContentColumn2>
                </NGOPageContainer2>
                    <NGORow>
                    <NGOContentColumn2>
                            <NGOCovidCard src ={card1}/>
                    </NGOContentColumn2>
                    <NGOContentColumn2>
                            <NGOCovidCard src ={card2}/>
                    </NGOContentColumn2>
            </NGORow>

            <NGOPageContainer3>
                    <NGOContentColumn1>
                    <NGOCovidInfoH1><JsxParser jsx={content.title2} components={{ Red }}/></NGOCovidInfoH1>
                        <NGOCovidInfop><JsxParser jsx={content.para3} components={{ Red }}/></NGOCovidInfop>
                        <NGOCovidInfop><JsxParser jsx={content.para4} components={{ Red }}/></NGOCovidInfop>
                    </NGOContentColumn1>
                    <NGOContentColumn2>
                    <Slideshow language={language}/>
                    </NGOContentColumn2>
            </NGOPageContainer3>

            <NGOPageContainer4>
                    <NGOContentColumn1>
                        <NGOCovidInfoH1><JsxParser jsx={content.title3} components={{ Red }}/></NGOCovidInfoH1>
                        <AboutTable language={language}/>
                    </NGOContentColumn1>
                    <NGOContentColumn2>
                        <NGOMap src = {map}/>
                    </NGOContentColumn2>
            </NGOPageContainer4>

            <NGOPageContainer5>
                    <NGOContentColumn1>
                    <NGOCovidInfoH1><JsxParser jsx={content.title4} components={{ Red }}/></NGOCovidInfoH1>
                    <NGOCovidInfop><JsxParser jsx={content.para5} components={{ Red }}/></NGOCovidInfop>
                    </NGOContentColumn1>
                    <NGOContentColumn2>
                        <ItemTable language={language}/>
                    </NGOContentColumn2>
            </NGOPageContainer5>

            <NGOPageContainer6>
                <NGOContentColumn1>
                    <NGOCovidInfoH1><JsxParser jsx={content.title5} components={{ Red }}/></NGOCovidInfoH1>
                    <NGOCovidInfop><JsxParser jsx={content.para6} components={{ Red }}/></NGOCovidInfop>
                    </NGOContentColumn1>
                    <NGOContentColumn2>
                    <NGOQuote><JsxParser jsx={content.quote} components={{ Red }}/></NGOQuote>
                </NGOContentColumn2>
                
            </NGOPageContainer6>
            <NGOPageContainer7> <NGOAwards src={awards}/></NGOPageContainer7>
           
    </>
    )
}

export default NGOPage
