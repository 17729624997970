import React from 'react';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

const Slideshow = (props) => {
  let content = {
   
    English: {
      images : [{
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621733867/tokyo/ngo3/1_jqgcya.png',
        alt:'Our researcher, Kamongla Longkumer in conversation with Impulse NGO Founder-Chairperson, Director and Case Manager'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621733866/tokyo/ngo3/2_i9fygx.jpg',
        alt: 'Distribution of ration kits in collaboration with its partners as part of COVID-19 outreach'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621733866/tokyo/ngo3/3_zxevg9.jpg',
        alt: 'Distribution of sanitary kits to adolescent girls and women'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621733867/tokyo/ngo3/4_aceurp.jpg',
        alt: 'Founder-Chairperson of INGON, with victims of human trafficking'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621733866/tokyo/ngo3/5_w8ol3k.jpg',
        alt: 'INGON receiving the Honour of “Achievement and Excellence in Human Rights Award” in 2019'
      },
      
    ]
  },
    Japanese: {
      images : [{
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621733867/tokyo/ngo3/1_jqgcya.png',
        alt:'Impulseの創設者兼会長、責任者、ケース担当者と話をする当方の調査員Kamongla Longkumer氏'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621733866/tokyo/ngo3/2_i9fygx.jpg',
        alt: '新型コロナウイルスの救済策として、協定パートナーと共に支援物資を配布'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621733866/tokyo/ngo3/3_zxevg9.jpg',
        alt: '青年期の女子や女性に衛生キットを配布'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621733867/tokyo/ngo3/4_aceurp.jpg',
        alt: '人身売買の被害に遭われた方々と写る団体創設者兼会長'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621733866/tokyo/ngo3/5_w8ol3k.jpg',
        alt: 'INGONは2019年に「人権アワード功績賞と優秀賞」を受賞'
      },
    ]
         
    }
  };
  props.language === "Japanese" ? (content = content.Japanese) : (content = content.English);
   

  return (
    <Carousel images={content.images} style={{ height: 300, width: 400 }} isLoop={true} hasThumbnails={false} hasThumbnailsAtMax={true} isAutoPlaying={true} autoPlayInterval={4000} hasMediaButton={false} hasIndexBoard={false} hasCaptions={'bottom'} hasLeftButton={true} hasRightButton={true} hasSizeButton={'topRight'} widgetsHasShadow={true}/>
  );
};

export default Slideshow;