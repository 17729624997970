import styled from 'styled-components'
import {Link} from 'react-router-dom'

export const Button = styled(Link)`
    border-radius: 50px;
    background: ${({primary}) => (primary ? '#D13E1B' : '#0c0c0c')};
    white-space: nowrap;
    font-weight: bold;
    padding: 10px 20px;
    color: ${({dark}) => (dark ? '#fefefe': '#fefefe')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease—in—out; 
    margin-right: 10px;
    margin-left: 10px;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease—in—out;
        background: ${({ primary }) => (primary ? '#fefefe' : '#D13E1B' )}; 
        color: ${({dark}) => (dark ? '#D13E1B': '#fefefe')};
    }
`;