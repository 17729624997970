import React, { useEffect } from 'react'
import './styles.scss'
import TextTransition, { presets } from "react-text-transition";

function commas(str) {
  return str.replace(/.(?=(?:.{3})+$)/g, '$&,');
}

const color = {
  color:'#D13E1B',}
const ProgressBar = (props) => {
  let content = {
    English: {
        goal: "GOAL:",
        raised: "RAISED:",
        supp: "SUPPORTERS",

    },
    Japanese: {
      goal: "目標金額:",
      raised: "現在の支援総額:",
      supp: "サポーター",
    }
  };
  // const [donations, setDonations] = useState([0,0]);
  // useEffect(() => {
  //   axios
  //     .get(`https://donation-scraper.herokuapp.com/donations`)
  //     .then((res) => {
  //       setDonations(res.data);
  //     }
  //     )
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }, []);

  const [index, setIndex] = React.useState(0);

    useEffect(() => {
        const intervalId = setInterval(() =>
          setIndex(index => index + 1),
          5000 // every 5 seconds
        );
        return () => clearTimeout(intervalId);
      }, []);
  const Heading = [
    "USD 36,550",
    "JPY 4,000,000",
  ];
  const Subheading = [
    `USD ${commas((38152).toFixed(0))}`,
    `JPY ${commas((38152*109.5).toFixed(0))}`,
];
props.language === "Japanese" ? (content = content.Japanese) : (content = content.English);

    return(
          
        <div className="container">
          <h1>{content.goal} <TextTransition text={Heading[index%Heading.length]} springConfig={presets.gentle} inline overflow /></h1>
          <div className="progressbar-container">
            <div className="progressbar-complete" style={{width: `${((38152*109.5)/40000).toFixed(2)}%`}}>
              <div className="progressbar-liquid"></div>
            </div>
            <span className= {(38152*109.5)/40000 < 50 ? "progress1" :"progress2"} >{((38152*109.5)/40000).toFixed(2)}%</span>
          </div>
          <h1>{content.raised} <TextTransition text={Subheading[index%Heading.length]} springConfig={presets.gentle} inline overflow/></h1>
          <h1 style={color}>{488} {content.supp}</h1>
        </div>
      );

          
    }

export default ProgressBar;