import React from 'react';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

const Slideshow = (props) => {
  let content = {
    English: {
      images: [{
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621559069/tokyo/ngo1/1_usahb2.png',
        alt:'Our researchers, Shreya and Ishita, in conversation with Mr. Shankar, founder of GMVS.'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621559069/tokyo/ngo1/2_bpwu83.jpg',
        alt: 'Distribution of sanitation kits'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621559068/tokyo/ngo1/3_bvoyl1.jpg',
        alt: 'Hans mobile medical vans bringing healthcare to remote villages.'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621559068/tokyo/ngo1/4_tcbtw6.jpg',
        alt: 'Mr. Shankar in conversation with a group of women.'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621559068/tokyo/ngo1/5_seg7aj.jpg',
        alt: 'GMVS being awarded the Mother Teresa Global Award in 2017.'
      },
    ]
        
    },
    Japanese: {
      images: [{
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621559069/tokyo/ngo1/1_usahb2.png',
        alt:'当方の調査員シュレヤとイシタが、GMVS設立者であるシャンカー氏と会話しているところ'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621559069/tokyo/ngo1/2_bpwu83.jpg',
        alt: 'GMVSが衛生キットの分配しているところ'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621559068/tokyo/ngo1/3_bvoyl1.jpg',
        alt: 'ハンスモバイルの医療用車が遠隔地の村に医療を提供していることろ'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621559068/tokyo/ngo1/4_tcbtw6.jpg',
        alt: 'シャンカー氏が女性の一段と会話を交わしているところ'
      },
      {
        src: 'https://res.cloudinary.com/djrobin17/image/upload/v1621559068/tokyo/ngo1/5_seg7aj.jpg',
        alt: '2017年、GMVSがマザー・テレサ世界賞を受賞しているところ'
      },
    ]
    }
  };
  props.language === "Japanese" ? (content = content.Japanese) : (content = content.English);

const style = {
  height: 300,
  width: 400,

}

  return (
  <Carousel images={content.images} style ={style} isLoop={true} hasThumbnails={false} hasThumbnailsAtMax={true} isAutoPlaying={true} autoPlayInterval={4000} hasMediaButton={false} hasIndexBoard={false} hasCaptions={'bottom'} hasLeftButton={true} hasRightButton={true} hasSizeButton={'topRight'} widgetsHasShadow={true}/>
  );
};

export default Slideshow;