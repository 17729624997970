import React from 'react'
import {
    Modal,
    useModal,
    ModalTransition,
  } from 'react-simple-hook-modal';
import { Button } from '../ButtonElement';
import 'react-simple-hook-modal/dist/styles.css';
import { TaxButton, TaxH, TaxP, Row, Column, Img, Bold } from './ModalElements';

const src = "https://res.cloudinary.com/djrobin17/image/upload/q_auto:low,f_auto/v1623684281/tokyo/Screenshot_2021-06-14_at_8.50.00_PM_onvzhi.png"


const ModalComponent = () => {
    const { isModalOpen, openModal, closeModal } = useModal();
    return (
        <>
            <TaxButton onClick={openModal}><Bold><u>For tax deductible donations (US only)</u></Bold> &gt;</TaxButton>
            <Modal
                isOpen={isModalOpen}
                transition={ModalTransition.NONE}
            >
            <TaxH>Tax benefits are available for US residents. Please follow these steps to make your donation tax deductible.</TaxH>
            <Row>
            <Column>
            <TaxP>1. Follow this <a href="https://secure.givelively.org/donate/asia-initiatives" target = "_blank" rel="noreferrer">link</a> to donate through Asia Initiatives (AI)—our partner NPO and a registered 501(c)(3) charity in the US.</TaxP>
            <TaxP>2. On the donation portal linked above, you'll see Tokyo for India as a partner for AI. Select the option for one-time donation and enter the amount you want to donate. Complete the transaction.</TaxP>
            <TaxP>3. Forward the automated email that you get for your donation to <a href="mailto:tokyoforindia@gmail.com">tokyoforindia@gmail.com</a> and <a href="mailto:victoria.ramirez@asiainitiatives.org">victoria.ramirez@asiainitiatives.org</a> for AI to credit your donation to Tokyo for India. Thank you!</TaxP>
            </Column>
            <Column>
            <Img src ={src} />
            </Column>
            </Row>
            <Button onClick={closeModal}>Close</Button>
            </Modal>
            
        </>
    )
}

export default ModalComponent
