import React from 'react';
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink  } from './SidebarElements'

const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="/gmvs" onClick={toggle}>GMVS</SidebarLink>
                    <SidebarLink to="/hrds" onClick={toggle}>HRDS</SidebarLink>
                    <SidebarLink to="/ingon" onClick={toggle}>INGON</SidebarLink>
                    <SidebarLink to="/parmarth" onClick={toggle}>Parmarth</SidebarLink>
                    <SidebarLink to="/samanta" onClick={toggle}>Samanta</SidebarLink>
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
