import React from 'react';
import { Table, Tr, Td, Th } from 'react-super-responsive-table';
import './AboutTable.css'

export default function AboutTable(props) {
  let content = {
    English: {
        h1: "Name of the Organization",
        h2: "Key person of the institution",
        h3: "Years in Existence",
        h4: "Postal Address",
        h5: "Email",
        h6: "Website",
        h7: "Contact Number",
        h8: "Society Registration Details",
        h9: "FCRA Registration Details",
        p1: "Impulse NGO Network",
        p2: "Ms. Hasina Kharbhih (founder and chairperson)"
        
    },
    Japanese: {
      h1: "団体名",
      h2: "団体の代表",
      h3: "団体の活動期間",
      h4: "住所",
      h5: "メールアドレス",
      h6: "ホームページ",
      h7: "電話番号",
      h8: "社会登録番号",
      h9: "FCRA番号",
      p1: "インパルス・NGO・ネットワーク",
      p2: "ハシナ・カービ(設立者、会長)"
    }
  };
  props.language === "Japanese" ? (content = content.Japanese) : (content = content.English);
  return (
    <Table>
      <Tr>
      <Th class='type'>{content.h1}</Th>
        <Td bgcolor='#fefefe'>{content.p1}</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h2}</Th>
        <Td bgcolor='#fefefe'>{content.p2}</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h3}</Th>
        <Td bgcolor='#fefefe'>28</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h4}</Th>
        <Td bgcolor='#fefefe'>Ranees Abode, 2nd Floor Lower Lachumiere, Near Horse Shoe Building, Shillong-793001, Meghalaya, India</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h5}</Th>
        <Td bgcolor='#fefefe'>feedback@impulsengonetwork.org, impulsecaseinfo.centre@gmail.com </Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h6}</Th>
        <Td bgcolor='#fefefe'><a href ="https://impulsengonetwork.org/">https://impulsengonetwork.org/</a></Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h7}</Th>
        <Td bgcolor='#fefefe'>+91 3642503140</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h8}</Th>
        <Td bgcolor='#fefefe'>SR/INGON-74/99 of 1999</Td>
        </Tr>
        <Tr>
        <Th class='type'>{content.h9}</Th>
        <Td bgcolor='#fefefe'>FCRA No. 214250081</Td>
        </Tr>
    </Table>
  );
}
