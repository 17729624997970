import React, { useState }from 'react'
import { Button } from '../ButtonElement'
import { HomeBtnWrapper } from '../Home/HomeElements'
import { StyledLink } from '../Team/TeamElements'
import { Heading, ImgWrap, InfoColumn1, InfoColumn2, InfoContainer, InfoRow, InfoWrapper, SocialIconLink, Subheading, TextWrapper } from './ContactElements'
import ContactForm from './ContactForm'
import { FaFacebook } from 'react-icons/fa'
import { RiInstagramFill } from 'react-icons/ri'
import { IoMail } from 'react-icons/io5'
import JsxParser from 'react-jsx-parser'

const Contact = (props) => {
    let content = {
        English: {
            title: "CONTACT US",
            para1: `For any questions or feedback, please send an email to <StyledLink href = "mailto:tokyoforindia@gmail.com">tokyoforindia@gmail.com</StyledLink> or fill this form. We will be in touch within 1-2 days.`,
            para2: `If you would like to join our team, click <StyledLink href ='https://forms.gle/nhqT7ZuevMebPcRh8'>here.</StyledLink> We would love to work with you!`,
            para3: `You can also follow campaign updates on our <StyledLink href = 'https://www.instagram.com/tokyoforindia/'>Instagram</StyledLink> or <StyledLink href="https://www.facebook.com/Tokyo-for-India-107580711538965">Facebook</StyledLink> page. `,
            para4: `<i>Donate. Spread the word. Stay safe, and stay healthy.</i>`,

        },
        Japanese: {
            title: "問い合わせ",
            para1: `ご質問やご意見は、<StyledLink href = "mailto:tokyoforindia@gmail.com">tokyoforindia@gmail.com</StyledLink> にメールをお送りいただくか、このフォームにご記入ください。1-2日以内にご連絡させていただきます。`,
            para2: `私たちのチームに参加したい方は、<StyledLink href ='https://forms.gle/nhqT7ZuevMebPcRh8'>ここ</StyledLink> をクリックしてください。`,
            para3: `また、<StyledLink href = 'https://www.instagram.com/tokyoforindia/'>Instagram</StyledLink>や<StyledLink href="https://www.facebook.com/Tokyo-for-India-107580711538965">Facebook</StyledLink>ページでも、キャンペーンの最新情報をご覧いただけます。`,
            para4: `<i>支援しよう。言葉を広めよう。健康で、お大事にしましょう。</i>`,
        }
      };
    const [hover, setHover]= useState(false)
    
    const onHover = () => {
        setHover(!hover)
    }
    props.language === "Japanese" ? (content = content.Japanese) : (content = content.English);

    return (
        <>
            <InfoContainer lightBg={true} id={"contact"}>
                <InfoWrapper>
                    <InfoRow imgStart={false}>
                        <InfoColumn1>
                            <TextWrapper>
                                <Heading>{content.title}</Heading>
                                <Subheading ><JsxParser jsx={content.para1} components={{ StyledLink }}/></Subheading>
                                <Subheading ><JsxParser jsx={content.para2} components={{ StyledLink }}/></Subheading>
                                <Subheading ><JsxParser jsx={content.para3} components={{ StyledLink }}/></Subheading>
                                <Subheading ><JsxParser jsx={content.para4} components={{ StyledLink }}/></Subheading>
                                <HomeBtnWrapper>
                                    <Button onClick={()=> window.location.href='https://milaap.org/fundraisers/support-marginalised-communities-in-india/'} onMounseEnter={onHover} onMouseLeave={onHover} primary='true' dark='false'>Donate Now  </Button>
                                    <Button onClick={()=> window.location.href='https://camp-fire.jp/projects/view/436736'} onMounseEnter={onHover} onMouseLeave={onHover} primary='true' dark='false'>支援する </Button>
                                </HomeBtnWrapper>
                                <ImgWrap>
                                <SocialIconLink href="https://www.instagram.com/tokyoforindia/" target = "_blank" aria-label="Instagram"><RiInstagramFill/></SocialIconLink>
                                <SocialIconLink href="https://www.facebook.com/Tokyo-for-India-107580711538965" target = "_blank" aria-label="Facebook"><FaFacebook/></SocialIconLink>
                                <SocialIconLink href="mailto:tokyoforindia@gmail.com" target = "_blank" aria-label="Mail"><IoMail/></SocialIconLink>
                                </ImgWrap>
                            </TextWrapper>
                        </InfoColumn1>
                        <InfoColumn2>
                            <ImgWrap>
                            <ContactForm language={props.language} />
                            </ImgWrap>
                        </InfoColumn2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
            
        </>
    )
}

export default Contact
