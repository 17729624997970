import styled from 'styled-components'

export const InfoContainer = styled.div`
    color: #fff;
    background: ${({lightBg}) => (lightBg ? '#f9f9f9' : '#010606')};

    @media screen and (max-width: 768px) {
        padding: 110px 0;
    }
`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 900px;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 30px;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        height: 1500px;
        padding: 0 24px;
    }
`;

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax()(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};
    justify-content: center;


    @media screen and (max-width: 768px) {
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1'  'col2'` : `'col1 col1' 'col2 col2'`)};
    }
`;

export const InfoColumn1 = styled.div`
    margin-bottom: 15px;
    margin-right: 50px;
    /* padding: 0 15px; */
    grid-area: col1;

    @media screen and (max-width: 768px) {
        margin-right: 0;
    }
`;

export const InfoColumn2 = styled.div`
    margin-bottom: 15px;
    grid-area: col2;
    margin-right: 40px;
    @media screen and (max-width: 768px) {
        margin-right: 0;
        padding: 0 15px;

    }
`;

export const TextWrapper = styled.div`
    max-width: 500px;
    padding-top: 0;
    /* padding-bottom: 60px; */
`;

export const Title = styled.p`
    color: #D13E1B;
    font-size: 18px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;

`;

export const Heading = styled.h1`
    margin-bottom: 25px;
    margin-top: 50px;
    font-size: 48px;
    line-height: 1.1.%;
    color: ${({lightText}) => (lightText ? '#f7f8fa' : "#010606")};
    text-align: center;

    @media screen and (max-width: 480px) {
        font-size: 32px;
        margin-top: 0;
        margin-bottom: 20px;

    }
`;

export const Subheading = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: ${({darkText}) => (darkText ? '#010606' : "#fff")};
`;

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const ImgWrap = styled.div`
    max-width: 700px;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;

export const Img = styled.img`
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;

    @media screen and (max-width: 480px) {
        margin: 10px 0 10px 0;
    }
`;

export const StyledLink = styled.a`
    text-decoration-color: #3457D5;
    color: #3457D5;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration-color: #3457D5;
        color: #3457D5;
    }
`;

export const Icon = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 12px 20px 0 20px;
    padding-right: 0;

`;


export const AdvisorTxt = styled.h1`
    margin-top: 10px;
    font-size: 20px;
    color: #fefefe;
    text-align: center;
`


export const IconWrap = styled.div`
    align-items: baseline;
    justify-content: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

`;

export const Icon2Wrap = styled.div`
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;

`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
`

export const AdvisorInfo = styled.h1`
    margin-top: 10px;
    font-size: 12px;
    color: #fefefe;
    text-align: center;
    width: 100px;
`
