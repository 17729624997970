import React from 'react'
import { HamburgerIcon, LangSwitcher, Nav,NavbarContainer, NavItem, NavLinks, NavLogo, NavMenu, NavSuper } from './Navbar2Elements'
import { animateScroll as scroll } from 'react-scroll'
import { FaBars } from 'react-icons/fa';

const Navbar = (props) => {
    let content = {
        English: {
            title: "Home",
        },
        Japanese: {
            title: "ホメパゲ",
        }
      };

    const toggleHome = () => {
        scroll.scrollToTop();
    }
    props.language === "Japanese" ? (content = content.Japanese) : (content = content.English);

    return (
        <>
            <Nav>
                <NavbarContainer>
                <NavSuper>
                    <NavLogo to="/" onClick={toggleHome}>{content.title}</NavLogo>
                    <LangSwitcher
                                value={props.language}
                                onChange={e => props.handleSetLanguage(e.target.value)}>
                                <option value="English">English</option>
                                <option value="Japanese">日本語</option>
                    </LangSwitcher>
                    </NavSuper>
                    <HamburgerIcon onClick={props.toggle}>
                        <FaBars />
                    </HamburgerIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks to="/gmvs">GMVS</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="/hrds">HRDS</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="/ingon">INGON</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="/parmarth">Parmarth</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="/samanta">Samanta</NavLinks>
                        </NavItem>
                    </NavMenu>
                </NavbarContainer>
            </Nav>
        </>
    );
};

export default Navbar
